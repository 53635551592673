import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { user } from '../../../features/authSlice';
import PersonIcon from '@mui/icons-material/Person';
import { Col, Container, Row } from 'react-bootstrap';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  checkUniqueIdValidity,
  checkUniqueUsernameValidity,
  checkUniqueValidity,
} from '../../../api/dataApi';
import { customLog, validateEmail } from '../../constants/helperFunctions';
import TextTranslation from '../../constants/TextTranslation';
import PhoneNumberField from '../../miscellaneousComponents/PhoneNumberField';
import { debounce } from 'lodash';

const useStyles = makeStyles(() => ({
  profileMain: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  profileImg: {
    margin: 'auto',
    width: 70,
    height: 70,
    borderRadius: 35,
    border: '2px solid #014493',
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileUpload: {
    position: 'absolute',
    marginTop: 30,
    marginLeft: 35,
    color: '#fff',
    backgroundColor: '#014493',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: 1,
  },
  input: {
    height: 33,
    width: '100%',
    border: '2px solid #014493',
    borderRadius: 20,
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
    paddingLeft: 10,
    display: 'flex',
    marginTop: 10,
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    '&::placeholder': {
      color: '#767676',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 11,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
  },
  btn: {
    width: 150,
    marginTop: 20,
    border: 'none',
    backgroundColor: '#014493',
    color: '#fff',
    cursor: 'pointer',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    borderRadius: 20,
  },
}));

const initialValues = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  cnic: '',
  phone_number: '',
  photo: '',
};
const PersonalInfo = ({ setNextTab }) => {
  const classes = useStyles();
  const [img, setImage] = useState('');
  const [duplicateEmail, setDuplicateEmail] = useState('');
  const [duplicateUsername, setDuplicateUsername] = useState('');
  const [duplicateId, setDuplicateId] = useState('');
  const country = useSelector((state) => state.country.country);

  const [inputValues, setInputValues] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    cnic: '',
    phone_number: '',
    photo: '',
  });

  const { values: valuesFromState } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language);

  const customInputStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    paddingRight: 10,
  };

  const customErrorStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    marginRight: 5,
    fontSize: 10,
    color: 'red',
    marginTop: 0,
    marginLeft: 5,
    fontFamily: 'Poopins-Regular',
  };

  // validation schema
  const handleImgUpload = (e) => {
    if (e.currentTarget.files[0]) {
      let file = e.currentTarget.files[0];
      setImage(file);
    }
  };
  useEffect(() => {
    if (valuesFromState?.get) {
      setInputValues({
        username: valuesFromState?.get('username'),
        first_name: valuesFromState?.get('first_name'),
        last_name: valuesFromState?.get('last_name'),
        email: valuesFromState?.get('email'),
        password: valuesFromState?.get('password'),
        confirmPassword: valuesFromState?.get('confirmPassword'),
        cnic: valuesFromState?.get('cnic'),
        phone_number: valuesFromState?.get('phone_number'),
        photo: valuesFromState?.get('photo'),
      });
    }
  }, [valuesFromState]);

  const customValidation = (values) => {
    const errors = {};
    if (!validateEmail(values.email) && !validateEmail(inputValues.email))
      errors.email = 'Invalid Email';

    if (values.username === '' && inputValues.username === '')
      errors.username = 'username is Required';
    if (values.first_name === '' && inputValues.first_name === '')
      errors.first_name = 'First name is Required';
    if (values.last_name === '' && inputValues.last_name === '')
      errors.last_name = 'Last name is Required';
    if (values.password === '' && inputValues.password === '')
      errors.password = 'Password is Required';
    if (
      values.confirmPassword !== values.password &&
      inputValues.confirmPassword !== inputValues.password
    )
      errors.confirmPassword = 'Passwords do not match';

    // console.log({ values, inputValues, errors });
    return errors;
  };

  const chequeUniqueEmail = async (e) => {
    if (validateEmail(e.target.value)) {
      const emailResponse = await checkUniqueValidity(e.target.value);
      if (emailResponse?.status) setDuplicateEmail('');
      else setDuplicateEmail('Email Already Exists');
    }
  };
  const chequeUniqueUsername = async (value) => {
    const usernameResponse = await checkUniqueUsernameValidity(value);

    if (usernameResponse?.status) setDuplicateUsername('');
    else setDuplicateUsername('Username Already Exists');
  };
  const chequeUniqueId = async (e) => {
    const idResponse = await checkUniqueIdValidity(e.target.value);
    customLog({ idResponse });
    if (idResponse?.status) setDuplicateId('');
    else setDuplicateId('CNIC Already Exists');
  };
  const delayedInputSearch = useMemo(
    () => debounce((e) => chequeUniqueEmail(e), 1000),
    []
  );
  const delayedUsernameValidation = useMemo(
    () => debounce((username) => chequeUniqueUsername(username), 1000),
    []
  );
  const delayedIdValidation = useMemo(
    () => debounce((e) => chequeUniqueId(e), 1000),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={Schema}
      validate={customValidation}
      // setValues={inputValues}
      // enableReinitialize
      onSubmit={(values) => {
        let formData = new FormData();
        formData.append('username', inputValues.username);
        formData.append('first_name', inputValues.first_name);
        formData.append('last_name', inputValues.last_name);
        formData.append('email', inputValues.email);
        formData.append('password', inputValues.password);
        formData.append('confirmPassword', inputValues.confirmPassword);
        formData.append('cnic', inputValues.cnic);
        formData.append('phone_number', inputValues.phone_number);
        formData.append('photo', img);
        formData.append('is_active', true);
        if (
          duplicateEmail !== 'Email Already Exists' &&
          duplicateUsername !== 'Username Already Exists'
        ) {
          dispatch(user(formData));
          setNextTab(1);
        }
      }}
    >
      {({ errors, handleChange, touched, handleBlur, isValid }) => (
        <Form>
          <Container>
            <Row className="justify-content-center">
              <div className={classes.profileMain}>
                {img === '' ? (
                  <PersonIcon sx={{ fontSize: 50, color: '#014493' }} />
                ) : (
                  <img
                    className={classes.profileImg}
                    src={URL.createObjectURL(img)}
                    alt={img ? 'profile-image' : null}
                  />
                )}

                <FileUploadOutlinedIcon
                  className={classes.fileUpload}
                  sx={{ fontSize: 18 }}
                />
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  accept="image/x-png,image/jpeg"
                  className="custom-file-input absolute-position"
                  onChange={(e) => {
                    handleImgUpload(e);
                  }}
                />
              </div>
            </Row>
            <Row
              className="justify-content-center"
              style={{
                display: 'flex',
                flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
              }}
            >
              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="username"
                  placeholder={TextTranslation.username[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  // onInput={delayedUsernameValidation}
                  value={inputValues?.username}
                  onChange={(e) => {
                    delayedUsernameValidation(e.target.value);
                    setInputValues((prev) => {
                      return { ...prev, username: e.target.value };
                    });
                    handleChange(e);
                  }}
                />
                {errors.username && touched.username ? (
                  <div style={customErrorStyle}>{errors.username}</div>
                ) : duplicateUsername !== '' ? (
                  <span style={customErrorStyle}>{duplicateUsername}</span>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="first_name"
                  placeholder={TextTranslation.firstName[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  value={inputValues?.first_name}
                  onChange={(e) => {
                    setInputValues((prev) => {
                      return { ...prev, first_name: e.target.value };
                    });
                    handleChange(e);
                  }}
                />
                {errors.first_name && touched.first_name ? (
                  <div style={customErrorStyle}>{errors.first_name}</div>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="last_name"
                  placeholder={TextTranslation.lastName[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  value={inputValues?.last_name}
                  onChange={(e) => {
                    setInputValues((prev) => {
                      return { ...prev, last_name: e.target.value };
                    });
                    handleChange(e);
                  }}
                />
                {errors.last_name && touched.last_name ? (
                  <div style={customErrorStyle}>{errors.last_name}</div>
                ) : null}
              </Col>

              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="email"
                  placeholder={TextTranslation.email[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  value={inputValues?.email}
                  onInput={delayedInputSearch}
                  onChange={(e) => {
                    setInputValues((prev) => {
                      return { ...prev, email: e.target.value };
                    });
                    handleChange(e);
                  }}
                />

                {errors.email && touched.email ? (
                  <div style={customErrorStyle}>{errors.email}</div>
                ) : duplicateEmail !== '' ? (
                  <span style={customErrorStyle}>{duplicateEmail}</span>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <InputMask
                  mask="*****-*******-*"
                  maskChar=" "
                  placeholder={`${
                    TextTranslation.cnic[lang.langIndex]
                  } *****-*******-*`}
                  name="cnic"
                  onInput={delayedIdValidation}
                  value={inputValues?.cnic}
                  className={classes.input}
                  style={customInputStyle}
                  onChange={(e) => {
                    setInputValues((prev) => {
                      return { ...prev, cnic: e.target.value };
                    });
                    handleChange(e);
                  }}
                />
                {duplicateId !== '' && (
                  <div style={customErrorStyle}>{duplicateId}</div>
                )}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div style={{ marginTop: 10 }}>
                  <PhoneNumberField
                    name="phone_number"
                    country={country}
                    placeholder={TextTranslation.phone[lang.langIndex]}
                    value={inputValues?.phone_number}
                    onChange={(e) => {
                      setInputValues((prev) => {
                        return { ...prev, phone_number: e };
                      });
                      handleChange(e);
                    }}
                  />
                </div>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="password"
                  placeholder={TextTranslation.password[lang.langIndex]}
                  type="password"
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  value={inputValues?.password}
                  onChange={(e) => {
                    setInputValues((prev) => {
                      return { ...prev, password: e.target.value };
                    });
                    handleChange(e);
                  }}
                />
                {errors.password && touched.password ? (
                  <div style={customErrorStyle}>{errors.password}</div>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="confirmPassword"
                  placeholder={TextTranslation.confirmPassword[lang.langIndex]}
                  type="password"
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  value={inputValues?.confirmPassword}
                  onChange={(e) => {
                    setInputValues((prev) => {
                      return { ...prev, confirmPassword: e.target.value };
                    });
                    handleChange(e);
                  }}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <div style={customErrorStyle}>{errors.confirmPassword}</div>
                ) : null}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <button className={classes.btn} type="submit" disabled={!isValid}>
                {TextTranslation.saveAndProceed[lang.langIndex]}
              </button>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalInfo;

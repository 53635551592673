// import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
// import { makeStyles } from '@mui/styles'
// import { Formik, Form } from 'formik'
// import {
//   Container,
//   Grid,
//   TextField,
//   Typography,
//   CircularProgress,
//   Button,
//   Card,
// } from '@mui/material'
// import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp'
// import Colors from '../../config/Colors'
// import * as yup from 'yup'
// import axios from 'axios'

// // styles
// const useStyles = makeStyles(() => ({
//   card: {
//     // marginTop: '20%'
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'center',
//     alignItems: 'center',
//     minHeight: '100vh',
//   },
//   paper: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     margin: '15% 5% 15% 5%',
//   },
//   btn: {
//     color: Colors.white,
//   },
//   forgetPassword: {
//     fontSize: 14,
//     cursor: 'pointer',
//   },
// }))

// const Login = () => {
//   let history = useHistory()
//   const classes = useStyles() // styles classes
//   const [load, setLoad] = useState(false) // loading state on button

//   //validation schema
//   let LoginSchema = yup.object().shape({
//     username: yup.string().min(2).required('Username is required'),
//     password: yup.string().min(6).required('Password is required'),
//   })

//   useEffect(() => {
//     return () => {
//       setLoad(false)
//     }
//   }, [])

//   return (
//     <Container component="main" maxWidth="xs">
//       <div className={classes.card}>
//         <Card raised>
//           <div className={classes.paper}>
//             <PersonAddSharpIcon
//               style={{ color: Colors.purple, fontSize: 40 }}
//             />
//             <Typography variant="h5" className="mb-3 mt-3">
//               <b>Dashboard Login</b>
//             </Typography>

//             {/* Login Form */}
//             <Formik
//               fullWidth
//               initialValues={{
//                 username: '',
//                 password: '',
//               }}
//               validationSchema={LoginSchema}
//               onSubmit={(values) => {
//                 // FormData append
//                 var bodyformData = new FormData()
//                 bodyformData.append('username', values.username)
//                 bodyformData.append('password', values.password)

//                 setLoad(true)
//                 axios({
//                   method: 'POST',
//                   url: 'https://api.zeerac.com/users/api/login',
//                   data: bodyformData,
//                   headers: {
//                     'Content-Type': 'application/json',
//                   },
//                 })
//                   .then((res) => {
//                     // if status == true then token store in local
//                     if (res.data.data.status) {
//                       localStorage.setItem('token', res.data.result.token)
//                       localStorage.setItem('username', res.data.result.username)
//                       history.push('/listing')
//                       alert(res.data.message)
//                       setLoad(false)
//                     } else {
//                       alert(res.data.message)
//                       setLoad(false)
//                     }
//                     console.log('Login response', res.data)
//                   })
//                   .catch((err) => {
//                     console.log('Login error', err)
//                     setLoad(false)
//                   })
//               }}
//             >
//               {({ errors, handleChange, touched, handleBlur, isValid }) => (
//                 // form fields
//                 <Form>
//                   <TextField
//                     fullWidth
//                     error={errors.username && touched.username}
//                     variant="outlined"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     className="mb-2"
//                     label="Username"
//                     name="username"
//                     helperText={
//                       errors.username && touched.username
//                         ? errors.username
//                         : null
//                     }
//                   />

//                   <TextField
//                     fullWidth
//                     error={errors.password && touched.password}
//                     variant="outlined"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     name="password"
//                     label="Password"
//                     type="password"
//                     className="mb-2"
//                     helperText={
//                       errors.password && touched.password
//                         ? errors.password
//                         : null
//                     }
//                   />
//                   {/* Login button */}
//                   <Button
//                     className="mt-3 btn bt"
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     disabled={!isValid}
//                     style={{ background: `linear-gradient(${Colors.primary})` }}
//                   >
//                     {load ? (
//                       <CircularProgress
//                         size={30}
//                         style={{ color: Colors.white }}
//                       />
//                     ) : (
//                       <span className={classes.btn}>Login</span>
//                     )}
//                   </Button>

//                   <Grid container className="mt-2">
//                     <Grid item xs>
//                       {/* forgot password */}
//                       <div
//                         style={{ textAlign: 'right' }}
//                         onClick={() => history.push('/')}
//                       >
//                         <span className={classes.forgetPassword}>
//                           Forget Password?
//                         </span>
//                       </div>
//                     </Grid>
//                   </Grid>
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         </Card>
//       </div>
//     </Container>
//   )
// }

// export default Login

import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { updateDoc, doc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
    error: null,
    loading: false,
  });

  const history = useHistory();

  const { email, password, error, loading } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    if (!email || !password) {
      setData({ ...data, error: 'All fields are required' });
    }
    try {
      //const result = await signInWithEmailAndPassword(auth, email, password);

      // await updateDoc(doc(db, "users", result.user.uid), {
      //   isOnline: true,
      // });
      var bodyformData = new FormData();
      bodyformData.append('email', email);
      bodyformData.append('password', password);
      axios({
        method: 'POST',
        url: 'https://api.zeerac.com/users/api/login',
        data: bodyformData,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          // if status == true then token store in local
          if (res.data.success) {
            localStorage.setItem('token', res.data.result.token);
            localStorage.setItem('username', res.data.result.username);
            signInWithEmailAndPassword(auth, email, password);
            toast.success(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            history.push('/listing');
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log('Login error', err);
          
        });

      setData({
        error: null,
        loading: false,
      });
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };

  return (
    <section>
      <h3>Log into your Account</h3>
      <form className="form" onSubmit={handleSubmit}>
        <div className="input_container">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </div>
        <div className="input_container">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
          />
        </div>
        {error ? <p className="error">{error}</p> : null}
        <div className="btn_container">
          <button className="btn" disabled={loading}>
            {loading ? 'Logging in ...' : 'Login'}
          </button>
        </div>
      </form>
    </section>
  );
};

export default Login;

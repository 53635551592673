import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Avatar, Grid, Card, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Colors from '../../config/Colors';
import Loader from '../loader';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllproperties,
  fetchAsyncProperties,
} from '../../features/store/property/propertySlice';
// sdadasdsad
const ListingTable = () => {
  let history = useHistory();

  const dispatch = useDispatch();
  const properties = useSelector(getAllproperties);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dataV, setDataV] = useState([]);

  useEffect(() => {
    dispatch(fetchAsyncProperties());
  }, [dispatch]);

  const handleClick = (event, dataV) => {
    setAnchorEl(event.currentTarget);
    setDataV(dataV);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="container" style={{ overflow: 'auto' }}>
        {properties.length > 0 ? (
          properties &&
          properties.map((d, index) => (
            <Card raised key={index} sx={{ maxHeight: 100, my: 2, py: 2 }}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={1} lg={1}>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    src={'https://api.zeerac.com/' + d.thumbnail}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push(`/listingDetail/${d.id}`)}
                  >
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      {d.name}
                    </span>
                    <span
                      style={{
                        fontSize: '0.8rem',
                        color: Colors.grey,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        width: '50%',
                      }}
                    >
                      {d.description}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={1} lg={1}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {d.is_active_listing ? (
                      <Tooltip title="active">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: 5,
                            backgroundColor: 'green',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Inactive">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: 5,
                            backgroundColor: 'red',
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <div style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                    {d?.user?.first_name}
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div style={{ fontSize: '0.8rem' }}>
                    {moment(d?.user?.date_joined).format('YYYY-MM-DD')}
                  </div>
                </Grid>
                <Grid item xs={1} lg={1}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, d.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        // width: "20ch",
                        boxShadow: '0px 0px #ffffff',
                        border: '0.1px solid grey',
                        zIndex: 10,
                      },
                    }}
                  >
                    <MenuItem>visible/invisible</MenuItem>
                    <MenuItem
                      onClick={() => history.push(`/updatelisting/${dataV}`)}
                    >
                      edit button
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Card>
          ))
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};

export default ListingTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { baseUrl } from "../components/constants/baseUrls";
import axios from "axios";

const initialState = {
  values: {},
  data: {},
  userData: null,
  companyData: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  isLoadingCompany: false,
  isSuccessCompany: false,
  isErrorCompany: false,
  message: "",
  loginResponse: {},
  isLoadingLogin: false,
  isErrorLogin: false,
  isSuccessLogin: false,
  messageLogin: "",
};

export const registerWithoutCompany = createAsyncThunk(
  "auth/registerWithoutCompany",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(baseUrl + "/users/user/", data);
      toast.success('Register SuccessFully');
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registerWithCompany = createAsyncThunk(
  "auth/registerWithCompany",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(baseUrl + "/users/company/", data.data,
      {
        headers: {
          Authorization: `Token ${data?.token}`,
        },
      }
      );
      toast.success('Register SuccessFully');
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      console.log({ error });
      toast.error(error);
      // toast(error?.response?.data?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(baseUrl + "/users/api/login", data);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.loginResponse = {};
      state.isLoadingLogin = false;
      state.isErrorLogin = false;
      state.isSuccessLogin = null;
      state.messageLogin = "";
    },
    user: (state, action) => {
      state.values = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Register Without Company
      .addCase(registerWithoutCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerWithoutCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.userData = action.payload;
      })
      .addCase(registerWithoutCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userData = {};
      })
      // Register With Company
      .addCase(registerWithCompany.pending, (state) => {
        state.isLoadingCompany = true;
      })
      .addCase(registerWithCompany.fulfilled, (state, action) => {
        state.isLoadingCompany = false;
        state.isErrorCompany = false;
        state.isSuccessCompany = true;
        state.companyData = action.payload;
      })
      .addCase(registerWithCompany.rejected, (state, action) => {
        state.isLoadingCompany = false;
        state.isErrorCompany = true;
        state.message = action.payload;
        state.companyData = {};
      })
      // login
      .addCase(login.pending, (state) => {
        state.isLoadingLogin = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoadingLogin = false;
        state.isSuccessLogin = true;
        state.loginResponse = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoadingLogin = false;
        state.isErrorLogin = true;
        state.messageLogin = action.payload;
        state.loginResponse = action.payload;
      });
  },
});

export const { reset, user } = authSlice.actions;
export default authSlice.reducer;

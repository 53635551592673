import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

import { Container, FormControl, Button, Typography } from "@mui/material";
import CustomField from "../../customComponents/customField/CustomField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Colors from "../../config/Colors";
import { baseUrl } from "./../constants/baseUrls";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "3% 10% 0% 10%",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
    marginTop: 5,
  },
  addNew: {
    color: Colors.grey,
    fontSize: 12,
    fontStyle: "italic",
    cursor: "pointer",
  },
}));

const AddNewProject = () => {
  const notify = () =>  toast('🦄 Project Successfully added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
  let history = useHistory();
  const classes = useStyles();
  const [addList, setAddList] = useState([
    { title: "", body: "", images: [], contains_files: false },
  ]);
  const [addFiles, setAddFiles] = useState([]);
  const [logo, setLogo] = useState();

  const handleInputChange = (e, index, check) => {
    if (check) {
      const { name, value } = e.target;
      const list = [...addList];
      list[index][name] = value;
      setAddList(list);
    } else {
      const { name, files } = e.target;
      let filesObj = { title: addList[index].title, file: files };
      let prevObj = addFiles;
      prevObj.push(filesObj);
      console.log(prevObj);
      setAddFiles(prevObj);
    }
  }; // handle click event of the Add

  const handleAddClick = () => {
    setAddList([...addList, { title: "", body: "" }]);
  }; // handle click event of the Remove

  const handleRemoveClick = (index) => {
    const list = [...addList];
    list.splice(index, 1);
    setAddList(list);
  };

  const [response, setResponse] = useState({});
  const [unique, setUnique] = useState(false);

  const submitProject = (data) => {
    data.content = addList; // let fData = new FormData() // fData.append('projectid', data.projectid) // fData.append('title', data.title) // fData.append('locality', data.locality) // fData.append('property_type', data.property_type) // fData.append('city', data.city) // fData.append('country', data.country) // fData.append('min_price', data.min_price) // fData.append('max_price', data.max_price) // fData.append('createddate', data.createddate) // fData.append('updateddate', data.updateddate) // fData.append('logo', data.logo) // fData.append('property_url', data.property_url)

    fetch(`${baseUrl}/` + "users/project/", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          notify();
          setResponse(response.result);
          response.result.content.map((item, index) => {
            uploadFiles(index, item.id);
          });
        }
        
        let formData = new FormData();
        formData.append("logo", logo);

        fetch(
          `${baseUrl}/` + "users/project/" + response.result.id + "/",
          {
            method: "PUT",
            body: formData,
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const initialValues = {
    projectid: "",
    title: "",
    locality: "",
    property_type: "",
    city: "",
    min_price: "",
    max_price: "",
    createddate: "",
    updateddate: "",
    logo: "",
    property_url: "",
    country: "",
    title: "",
    body: "",
    image: "",
    content: [
      {
        id: " ",
        // files: [],
        title: "title 1 ",
        body: "desc 1 ",
        contains_files: true,
      },
    ],
  };

  const uploadFiles = (index, id) => {
    console.log(addFiles[index], "files");
    let formData = new FormData();
    formData.append("len", addFiles[index].file.length);
    formData.append("id", id);
    formData.append("logo", logo);
    Array.from(addFiles[index].file).forEach((file, i) => {
      formData.append("title", addFiles[index].title);
      formData.append("file[" + i + "]", file);
      formData.append("file_name", file);
    }); 
    fetch(`${baseUrl}/` + "users/upload-files/", {
      method: "POST",
      body: formData, 
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("upload files response", response);
        if (response.status === true) {
          console.log(response, "rs");
        }
      })
      .catch((err) => {
        console.log(err);
      });
   
  };

  return (
    <>
      <Container fullWidth>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" className="text-center mb-2">
            Add New Project            
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              let x = {
                projectid: values.projectid,
                title: values.title,
                locality: values.locality,
                property_type: values.property_type,
                city: values.city,
                content: initialValues.content,
                country: values.country,
                createddate: values.createddate,
                updateddate: values.updateddate,
                property_url: values.property_url,
                min_price: values.min_price,
                max_price: values.max_price,
              };
              submitProject(x);
            }}
          >
            {({ handleChange, handleBlur }) => (
              <Typography component="div" sx={{ width: "100%" }}>
                <Form>
                  <FormControl fullWidth className={classes.fields}>
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Project Id"
                      name="projectid"
                      placeholder="Project Id"
                    />
                    <div style={{ padding: 10 }} />
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="title"
                      label="Project Title"
                      placeholder="Project Title"
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.fields}>
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Property Type"
                      placeholder="Property Type"
                      name="property_type"
                    />
                    <div style={{ padding: 10 }} />
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="locality"
                      label="Locality"
                      placeholder="Locality"
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.fields}>
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="city"
                      label="City"
                      placeholder="City"
                    />
                    <div style={{ padding: 10 }} />
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="country"
                      label="Country"
                      placeholder="Country"
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.fields}>
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Minimum Price"
                      placeholder="Minimum Price"
                      type="number"
                      name="min_price"
                    />
                    <div style={{ padding: 10 }} />
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Maximum Price"
                      placeholder="Maximum Price"
                      type="number"
                      name="max_price"
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.fields}>
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Created Date"
                      placeholder="Created Date"
                      type="date"
                      name="createddate"
                    />
                    <div style={{ padding: 10 }} />
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Updated Date"
                      placeholder="Updated Date"
                      type="date"
                      name="updateddate"
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.fields}>
                    <CustomField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Property URL"
                      placeholder="Property URL"
                      name="property_url"
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      display: "flex",
                      border: "1px solid #d5d5d5",
                      flexDirection: "row",
                      borderRadius: 5,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: 5,
                      padding: 5,
                    }}
                  >
                    <label
                      style={{
                        color: "#d5d5d5",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      Property Logo: &nbsp;
                    </label>
                    <input
                      id="logo"
                      name="logo"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      label="Property Logo"
                      onChange={(event) => {
                        setLogo(event.target.files[0]);
                        console.log(event.target.files[0]);
                      }}
                    />
                  </FormControl>
                  <Typography
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  ></Typography>
                  {addList.map((item, index) => (
                    <>
                      <FormControl
                        key={index}
                        fullWidth
                        className={classes.fields}
                      >
                        <CustomField //onChange={handleChange}
                          onBlur={handleBlur}
                          name="title"
                          label="Title"
                          placeholder="Title" //value={item.title}
                          onChange={(e) => handleInputChange(e, index, true)}
                        />
                        <DeleteOutlineIcon
                          fontSize="small"
                          sx={{ cursor: "pointer" }} //onClick={handleRemove}
                          onClick={() => handleRemoveClick(index)}
                        />
                      </FormControl>
                      <FormControl fullWidth className={classes.fields}>
                        <CustomField //onChange={handleChange}
                          onBlur={handleBlur}
                          name="body"
                          label="Description"
                          placeholder="Description"
                          multiline
                          minRows={4} //value={item.description}
                          onChange={(e) => handleInputChange(e, index, true)}
                        />
                      </FormControl>
                      <FormControl fullWidth className={classes.fields}>
                        <input
                          id="image"
                          className="custom-file-input"
                          type="file"
                          accept="image/x-png,image/jpeg"
                          multiple="multiple" //onChange={handleChange}
                          name="image"
                          label="Image"
                          placeholder="Image" //value={item.image} // onChange={(e) => { //   console.log(e.target.files) // }}
                          onChange={(e) => handleInputChange(e, index, false)} //onChange={onChange}
                        />
                      </FormControl>
                    </>
                  ))}
                  <Typography
                    component="div"
                    className={classes.addNew} //onClick={handleAddMore}
                    onClick={handleAddClick}
                  >
                    Add More ?                    
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      variant="outlined"
                      type="submit" // onClick={submitProject} //onClick={() => history.push('/viewnewproject')}
                    >
                      Add Project                      
                    </Button>
                  </div>
                  <br />                   
                  {/* <div onClick={() => history.push("/viewnewproject")}>
                    next                    
                  </div> */}
                </Form>
              </Typography>
            )}
          </Formik>
        </div>
       
      </Container>
      <ToastContainer />
    </>
  );
};

export default AddNewProject;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../components/constants/baseUrls';
import axios from 'axios';

const initialState = {
  data: [],
  dataDetail: [],
  agency_Listings: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const agency = createAsyncThunk(
  'agency/agency',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(baseUrl + '/users/company/', data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agencyDetail = createAsyncThunk(
  'agency/agencyDetail',
  async (id, data, thunkAPI) => {
    try {
      const response = await axios.get(baseUrl + `/users/company/${id}`, data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const agencyListings = createAsyncThunk(
  'agency/agencyListings',
  async (id, data, thunkAPI) => {
    try {
      const response = await axios.get(
        baseUrl + `/users/agency-listings/?company=${id}`,
        data
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      // agency-archive
      .addCase(agency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agency.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(agency.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = [];
      })
      // agency-detail
      .addCase(agencyDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agencyDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dataDetail = action.payload;
      })
      .addCase(agencyDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dataDetail = [];
      })
      // agency-listings
      .addCase(agencyListings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agencyListings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.agency_Listings = action.payload;
      })
      .addCase(agencyListings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agency_Listings = [];
      });
  },
});

export const { reset } = agencySlice.actions;
export default agencySlice.reducer;

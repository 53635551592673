import moment from 'moment';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { DEFAULT_AVATAR } from '../../constants/global';
import { baseUrl } from '../../constants/baseUrls';
import { Card, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { agentDetail } from '../../../features/agentSlice';
import { customLog } from '../../constants/helperFunctions';
import TextTranslation from '../../constants/TextTranslation';

const useStyles = makeStyles(() => ({
  card: {
    border: '1px solid #014493',
    marginTop: 10,
  },
  first: {
    height: 150,
    borderBottom: '1px solid #014493',
    //position: 'relative',
  },
  image: {
    // width: 170,
    height: 150,
    borderBottom: '1px solid #014493',
  },
  favorite: {
    color: '#014493',
    flexGrow: 1,
    position: 'absolute',
    right: 10,
  },
  firstSub: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1,
    marginLeft: 50,
    marginTop: 20,
  },
  name: {
    color: '#014493',
    fontFamily: 'Poopins-Regular',
    fontSize: '1.6rem',
    textTransform: 'capitalize',
  },
  profession: {
    color: '#014493',
    fontFamily: 'Poopins-Regular',
    fontSize: 14,
    textTransform: 'capitalize',
  },
  firstSub2: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1,
    marginLeft: 50,
    marginTop: 20,
  },
  label: {
    color: '#014493',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 12,
  },
  value: {
    color: '#014493',
    fontFamily: 'Poopins-Regular',
    fontSize: 12,
  },
  second: {
    marginTop: 5,
    marginBottom: 20,
  },
}));

const PersonalInfo = () => {
  const classes = useStyles();
  const paramFromAgentList = useParams();
  const dispatch = useDispatch();
  const { dataDetail } = useSelector((state) => state.agent);

  console.log("ccccccccccccccccccc",dataDetail)
  const lang = useSelector((state) => state.language);

  const customStyle = {
    display: 'flex',
    flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
  };

  const ReduxData = async () => {
    await dispatch(agentDetail(paramFromAgentList.id));
  };

  useEffect(() => {
    ReduxData();
  }, []);

  useEffect(() => {
    customLog({ dataDetail });
  }, [dataDetail]);

  return (
    <Card className={classes.card} sx={{ borderRadius: 5 }}>
      <div className={classes.first}>
        <Grid
          container
          display="flex"
          flexDirection={lang.langIndex === 2 ? 'row-reverse' : 'row'}
        >
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <img
              alt="profile"
              src={
                dataDetail?.result?.photo
                  ? `${baseUrl}/${dataDetail?.result?.photo}`
                  : DEFAULT_AVATAR
              }
              className={classes.image}
            />
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={7} xl={7}>
            <div className={classes.firstSub}>
              <span className={classes.name}>
                {dataDetail?.result?.full_name}
              </span>
              <span className={classes.profession}>
                {dataDetail?.result?.company}
              </span>
            </div>
            <div className={classes.firstSub2}>
              {dataDetail?.result?.nationality && (
                <div className={classes.nationality}>
                  <span className={classes.label}>Nationality: &nbsp;</span>
                  <span className={classes.value}>
                    {dataDetail?.result?.nationality}
                  </span>
                </div>
              )}
              {dataDetail?.result?.languages && (
                <span className={classes.profession}>
                  <span className={classes.label}>Languages: &nbsp;</span>
                  <span className={classes.value}>
                    {dataDetail?.result?.languages}
                  </span>
                </span>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.second}>
        <div
          className={classes.value}
          style={{
            marginLeft: 20,
            marginRight: 20,
            display: 'flex',
            flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
          }}
        >
          {TextTranslation.personalInformation[lang.langIndex]}
        </div>
        <Grid
          container
          justifyContent={'space-around'}
          display="flex"
          flexDirection={lang.langIndex === 2 ? 'row-reverse' : 'row'}
        >
          <Grid item lg={5} sx={{ lineHeight: 1.3 }}>
            <div style={customStyle}>
              <span className={classes.label}>
                {TextTranslation.activeListings[lang.langIndex]}&nbsp;
              </span>
              <span className={classes.value}>
                {dataDetail?.result?.active_listing_count} Properties
              </span>
            </div>
            <div style={customStyle}>
              <span className={classes.label}>
                {TextTranslation.accountId[lang.langIndex]}&nbsp;
              </span>
              <span className={classes.value}>{dataDetail?.result?.id}</span>
            </div>
            <div style={customStyle}>
              <span className={classes.label}>
                {TextTranslation.memberSince[lang.langIndex]}&nbsp;
              </span>
              <span className={classes.value}>
                {moment(dataDetail?.result?.created_at).format('YYYY/MM/DD')}
              </span>
            </div>
          </Grid>
          <Grid item lg={5}>
            <div style={customStyle}>
              <span className={classes.label}>
                {TextTranslation.areas[lang.langIndex]}&nbsp;
              </span>
              <span className={classes.value}>
                {dataDetail?.result?.area ||
                  TextTranslation.notProvided[lang.langIndex]}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default PersonalInfo;

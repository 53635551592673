import React, { useState, useEffect, useContext } from "react";
import { Container, FormControl, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router";
import { Formik, Form } from "formik";
import CustomField from "../../customComponents/customField/CustomField";
//import * as yup from 'yup'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getPropertyDetailForUpdate,
  postAsyncUpdatePropertyDetail,
} from "../../features/store/property/propertySlice";

// styles
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "5% 10% 0% 10%",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
}));

const LocationInfo = () => {
  let paramFromDetailListing = useParams();
  const classes = useStyles();
  const data = useSelector(getPropertyDetailForUpdate);
  const dispatch = useDispatch();

  const getUpdateData = (values) => {
    const id = paramFromDetailListing.id;
    const val = { values, id };
    dispatch(postAsyncUpdatePropertyDetail(val));
  };

  return (
    <Container fullWidth>
      <div className={classes.paper}>
        <Formik
          fullWidth
          initialValues={{
            address: data.address,
            city: data?.user?.city,
            area: data?.user?.area,
            loca: data?.user?.loca,
            lng: data.lng,
            lat: data.lat,
          }}
          onSubmit={(values) => {
            getUpdateData(values);
          }}
        >
          {({ handleChange, handleBlur }) => (
            <div style={{ width: "100%" }}>
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="profileEditField">
                      <label>Full Address</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
              
                        name="address"
                        defaultValue={data.address}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>City</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                   
                        name="city"
                        defaultValue={data?.user?.city}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Area</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="area"
                  
                        defaultValue={data?.user?.area}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Location</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="loca"
                    
                        defaultValue={data?.user?.loca}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Longitude</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                  
                        name="lng"
                        defaultValue={data.lng}
                        type="number"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Latitude</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                     
                        name="lat"
                        defaultValue={data.lat}
                        type="number"
                      />
                    </div>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <Button variant="outlined" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default LocationInfo;

import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import TextTranslation from '../../constants/TextTranslation';
import AutocompleteSearch from '../../layouts/HomeSearch/AutocompleteSearch'

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
});

const useStyles = makeStyles(() => ({
  input: {
    height: 24,
    width: 150,
    border: '1px solid #014493',
    borderRadius: 20,
    color: '#014493',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
    paddingLeft: 10,
    display: 'flex',
    margin: '0 10px',
    alignItems: 'center',
    '&::placeholder': {
      color: '#014492',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 11,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
    [theme.breakpoints.down('lg')]: {
      width: 100,
    },
  },
  btn: {
    backgroundColor: '#014493',
    color: '#fff',
    border: 'none',
    height: 24,
    width: 100,
    borderRadius: 20,
    fontFamily: 'Poopins-Regular',
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: 0,
  },
}));

const AgentFilter = ({
  agentName,
  setAgentName,
  city,
  setCity,
  handleSearch,
  handleReset,
}) => {
  const classes = useStyles();
  const country = useSelector((state) => state.country.country);
  const lang = useSelector((state) => state.language);

  const user = JSON.parse(sessionStorage.getItem('authentication') || '{}');

  const customBodyStyle = {
    padding: '15px 0 10px 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    position: 'static',
    width: '100%',
    flexWrap: 'wrap',
  };

  const customPlaceholderStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    paddingRight: 10,
  };

  return (
    <form>
      <div style={customBodyStyle}>
        <input
          type="text"
          className={classes.input}
          style={customPlaceholderStyle}
          placeholder={TextTranslation.agentName[lang.langIndex]}
          value={agentName}
          onChange={(e) => {
            setAgentName(e.target.value);
          }}
        />
        <AutocompleteSearch
          name="city"
          country={country}
          searchOption={['(regions)']}
          customStyle={classes.input}
          style={customPlaceholderStyle}
          value={city}
          handleChange={(e) => setCity(e.target.value)}
          placeholder={TextTranslation.city[lang.langIndex]}
        />

        <button type="submit" className={classes.btn} onClick={handleSearch}>
          {TextTranslation.searchAgent[lang.langIndex]}
        </button>
        <button
          className={classes.btn}
          style={{ margin: 10 }}
          onClick={handleReset}
        >
          {TextTranslation.reset[lang.langIndex]}
        </button>

        {user?.token && (
          <Link to="/add-agent">
            <button
              className={classes.btn}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              {TextTranslation.addAgent[lang.langIndex]}
            </button>
          </Link>
        )}
      </div>
    </form>
  );
};

export default AgentFilter;

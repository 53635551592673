import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const fetchTrendsData = async (country, city, area) => {
  try {
    const response = await api.get(
      `users/property/trends?country=${country}&city=${city}&area=${area}`
    );
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: fetchTrendsData', { error });
    return false;
  }
};
export const checkUniqueEmailExists = async (email) => {
  try {
    const response = await api.get(`users/unique-email/?email=${email}`);
    if (response) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error in: checkUniqueEmailExists', { error });
    return false;
  }
};
export const checkUniqueValidity = async (email) => {
  try {
    const response = await api.get(`users/unique-email/?email=${email}`);
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: fetchTrendsData', { error });
    return false;
  }
};
export const checkUniqueUsernameValidity = async (username) => {
  try {
    const response = await api.get(
      `users/unique-username/?username=${username}`
    );
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: fetchTrendsData', { error });
    return false;
  }
};
export const checkUniqueIdValidity = async (cnic) => {
  try {
    const response = await api.get(`users/unique-cnic/?cnic=${cnic}`);
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: fetchTrendsData', { error });
    return false;
  }
};
export const addAgentToDatabase = async (data) => {
  try {
    const response = await api.post(`users/user/`, data);
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: addAgentToDatabase', { error });
    return false;
  }
};

export const deleteAgentfromDatabase = async (id) => {
  try {
    const response = await api.delete(`users/user/${id}`,);
    toast.info("Agent Deleted SuccessFully")
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: deleteAgentToDatabase', { error });
    return false;
  }
};

export const deleteAgencyfromDatabase = async (id) => {
  try {
    const token= localStorage.getItem("token");
    const response = await api.delete(`users/company/${id}`,{headers:{
      Authorization: `token ${token}`
    }});
    toast.info("Agency Deleted SuccessFully")
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log('error in: deleteAgencyToDatabase', { error });
    return false;
  }
};

export const verifyAgent = async (code) => {
  try {
    const response = await api.post(`users/confirm/verify`, {
      code,
    });
    if (response) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const reVerify = async (email) => {
  try {
    const response = await api.post(`users/verification/code`, { email });
    if (response) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const fetchTutorialVideo = async (token) => {
  try {
    const response = await api.get(`users/video-course/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log("error in: fetchTutorialVideo", { error });
    return false;
  }
};
export const fetchTutorialQuestions = async (token) => {
  try {
    const response = await api.get(`users/course/performance/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (response) {
      return response.data;
    } else {
      return false;
    }
    // console.log({ response });
  } catch (error) {
    console.log("error in: fetchTutorialQuestions", { error });
    return false;
  }
};

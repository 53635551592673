import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../../customComponents/layout/Layout";
import ChatRoom from "./ChatRoom";
const Chat = () => {
  return (
    <Layout>
      <Container fluid className="py-2">
        <ChatRoom />
      </Container>
    </Layout>
  );
};

export default Chat;

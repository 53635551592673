import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../../customComponents/layout/Layout";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  LineChart,
  Cell,
} from "recharts";

const dataLineChart = [
  {
    name: "2010",
    price: 2000000,
  },
  {
    name: "2011",
    price: 3000000,
  },
  {
    name: "2012",
    price: 4000000,
  },
  {
    name: "2013",
    price: 5000000,
  },
  {
    name: "2014",
    price: 6000000,
  },
  {
    name: "2015",
    price: 4000000,
  },
  {
    name: "2017",
    price: 9000000,
  },
  {
    name: "2018",
    price: 12000000,
  },
  {
    name: "2019",
    price: 20000000,
  },
];
const dataAreaChart = [
  {
    name: "DHA Phase 2",
    count: 40,
  },
  {
    name: "Johar Town G3",
    count: 150,
  },{
    name: "Gulber 3",
    count: 120,
  },{
    name: "Anar kali",
    count: 100,
  },{
    name: "Iqbal Town",
    count: 140,
  },
  {
    name: "Green Twon",
    count: 90,
  },{
    name: "Wapda Town",
    count: 110,
  },{
    name: "Amin town",
    count: 30,
  },
]
const data = [
  {
    name: "Sahiwal",
    total: 200,
    house: 120,
    shops: 10,
    plaza: 40,
    plot: 30,
  },
  {
    name: "Lahore",
    total: 400,
    house: 180,
    shops: 140,
    plaza: 60,
    plot: 40,
  },
  {
    name: "Islamabad",
    total: 320,
    house: 40,
    shops: 20,
    plaza: 40,
    plot: 60,
  },
  {
    name: "Karachi",
    total: 400,
    house: 180,
    shops: 40,
    plaza: 120,
    plot: 80,
  },
  {
    name: "Multan",
    total: 150,
    house: 30,
    shops: 50,
    plaza: 10,
    plot: 60,
  },
  {
    name: "Rawalpindi",
    total: 160,
    house: 40,
    shops: 20,
    plaza: 40,
    plot: 60,
  },
  {
    name: "Peshawar",
    total: 260,
    house: 100,
    shops: 40,
    plaza: 100,
    plot: 20,
  },
  {
    name: "Faisalabad",
    total: 300,
    house: 230,
    shops: 10,
    plaza: 30,
    plot: 40,
  },
  
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  label
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {name}
      {"   "}
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ListingChart = () => {
  const [xdata, setData] = useState([]);

  const getChartData = async () => {
    const response = await axios.get(
      `https://api.zeerac.com/users/property/trends/`
    );
    console.log("data", response.data.data);
    setData(response.data.data);
  };

  useEffect(() => {
    getChartData();
  }, []);

  console.log("filter array", xdata);

  return (
    <Layout>
      <h1 style={{ textAlign: "center", margin: "10px auto" }}>
        All Property Detail
      </h1>
      <div style={{ paddingLeft: "80px" }}>
        <h3 style={{ textAlign: "left", margin: "10px auto" }}>
          Property Price
        </h3>
        <LineChart
          width={980}
          height={400}
          data={dataLineChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="price"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </div>
      <div style={{ paddingLeft: "80px" }}>
        <h3 style={{ textAlign: "left", margin: "10px auto" }}>
          User Interested Area
        </h3>
        <ComposedChart
          width={980}
          height={400}
          data={dataAreaChart}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" barSize={20} fill="#413ea0" />
        </ComposedChart>
      </div>
      <div style={{ paddingLeft: "80px" }}>
        <h3 style={{ textAlign: "left", margin: "10px auto" }}>
          Country Property Detail
        </h3>
        <ComposedChart
          width={980}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="total"
            fill="#8884d8"
            stroke="#8884d8"
          />
          <Bar dataKey="total" barSize={20} fill="#413ea0" />
          <Line type="monotone" dataKey="total" stroke="#ff7300" />
          <Scatter dataKey="house" fill="pink" />
          <Scatter dataKey="shops" fill="red" />
          <Scatter dataKey="plaza" fill="orange" />
          <Scatter dataKey="plot" fill="yellow" />
        </ComposedChart>
      </div>

      <div style={{ paddingLeft: "80px" }} className="pie-graph-style">
        <h3 style={{ textAlign: "left", margin: "10px auto" }}>
          User Interested Area
        </h3>
        <PieChart width={700} height={700} label>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            nameKey="name"
            outerRadius={200}
            fill="#8884d8"
            dataKey="total"
          >
            {data.map((entry, index) => {
              console.log("entry");
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              );
            })}
          </Pie>
          <LabelList dataKey="name" position="top" />
        </PieChart>
      </div>
    </Layout>
  );
};
export default ListingChart;

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AddIcon from '@mui/icons-material/Add';
import ProfileMenu from '../profileMenu/ProfileMenu';
import ViewListIcon from '@mui/icons-material/ViewList';
import Colors from '../../config/Colors';
import BusinessIcon from '@mui/icons-material/Business';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChatIcon from '@mui/icons-material/Chat';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import logo from '../../assets/logo.png';
import whiteLogo from '../../assets/whiteLogo.svg';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GroupIcon from '@mui/icons-material/Group';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
const drawerWidth = 270;

const navbarItems = [
  { name: 'Listing', iconSvg: <ViewListIcon />, linkAction: `listing` },
  { name: 'Projects', iconSvg: <BusinessIcon />, linkAction: 'projects' },
  {
    name: 'ListingChart',
    iconSvg: <BarChartIcon />,
    linkAction: 'listingchart',
  },
  {
    name: 'Courses',
    iconSvg: <LibraryBooksIcon />,
    linkAction: 'course',
  },
  {
    name: 'Agents',
    iconSvg: <GroupIcon />,
    linkAction: 'agents',
  },
  {
    name: 'Agencies',
    iconSvg: <CorporateFareIcon />,
    linkAction: 'agencies',
  },
  {
    name: 'Register Company',
    iconSvg: <HowToRegIcon />,
    linkAction: 'register-company',
  },
  // { name: "Trash", iconSvg: <InboxIcon />, linkAction: "" },
  // { name: "Spam", iconSvg: <MailIcon />, linkAction: "" },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Layout = ({ children }) => {
  let history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: '#014493' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <img alt="logo" src={whiteLogo} width={120} />
          </Typography>
          <div style={{ right: 30, position: 'absolute' }}>
            <ProfileMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ backgroundColor: '#014493' }}
      >
        <DrawerHeader sx={{ backgroundColor: '#fff', color: Colors.primary }}>
          <div style={{ left: 10, position: 'absolute' }}>
            <img alt="logo" src={logo} width={100} />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon style={{ color: Colors.primary }} />
            ) : (
              <ChevronLeftIcon style={{ color: Colors.primary }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ backgroundColor: '#014493', color: 'white' }}>
          <ListItem button onClick={() => history.push('/chat')}>
            <ListItemIcon style={{ color: Colors.white }}>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText>Chat</ListItemText>
          </ListItem>
          <ListItem button onClick={() => history.push('/addnewproject')}>
            <ListItemIcon style={{ color: Colors.white }}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText>Add New Project</ListItemText>
          </ListItem>
        </List>
        <Divider />

        <List sx={{ backgroundColor: '#014493', color: 'white' }}>
          {navbarItems.map((navItem, index) => (
            <ListItem
              button
              key={navItem.name}
              onClick={() => history.push(`/${navItem.linkAction}`)}
            >
              <ListItemIcon style={{ color: Colors.white }}>
                {navItem.iconSvg}
              </ListItemIcon>
              <ListItemText>{navItem.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />

        {children}
      </Box>
    </Box>
  );
};

// ViewListIcon
export default Layout;

import React, { useState } from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@mui/material'
import BasicInfo from '../../components/updateListingForms/BasicInfo'
import FeatureInfo from '../../components/updateListingForms/FeatureInfo'
import LocationInfo from '../../components/updateListingForms/LocationInfo'
import { useHistory } from 'react-router'

const steps = ['Basic Information', 'Feature Details', 'Location']

const CustomStepper = () => {
  let history = useHistory()
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>{steps[0]}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{steps[1]}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{steps[2]}</StepLabel>
        </Step>
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {activeStep === 0 && <BasicInfo />}
            {activeStep === 1 && <FeatureInfo />}
            {activeStep === 2 && <LocationInfo />}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <Button
                variant="outlined"
                onClick={() => history.push('/listing')}
              >
                Finish
              </Button>
            ) : (
              <Button onClick={handleNext} variant="outlined">
                Next
              </Button>
            )}
            {/* <Button onClick={handleNext} variant="outlined">
              {activeStep === steps.length - 1 ? 'Update' : 'Next'}
            </Button> */}
          </Box>
        </React.Fragment>
      )}
    </Box>
  )
}

export default CustomStepper

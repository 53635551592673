import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Avatar } from "@mui/material";
import Layout from "../../customComponents/layout/Layout";
import {
  getAllProjects,
  fetchAsyncPropjects,
} from "./../../features/store/property/propertySlice";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const columns = [
  { id: "logo", label: "Logo", minWidth: 170 },
  { id: "title", label: "Title", minWidth: 100 },
  {
    id: "property_type",
    label: "Property Type",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "max_price",
    label: "Maximum Price",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "min_price",
    label: "Minimum Price",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "id",
    label: "Action",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const AllProjects = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const allProjects = useSelector(getAllProjects);
  console.log("this is the projects", allProjects);
  useEffect(() => {
    dispatch(fetchAsyncPropjects());
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const [dataV, setDataV] = useState([]);

  const handleClick = (event, dataV) => {
    setAnchorEl(event.currentTarget);
    setDataV(dataV);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


 
 

  return (
    <Layout>
      <div className="container property_table">
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "84vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <h3>Projects</h3>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      <b> {column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allProjects?.results?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "logo" ? (
                            <TableCell key={column.id} align={column.align}>
                              <Avatar
                                sx={{ width: 50, height: 50 }}
                                src={"https://api.zeerac.com/" + value}
                              />
                            </TableCell>
                          ) : 
													column.id === "id" ? (
                            <TableCell key={column.id} align={column.align}>
                              <Button variant="contained" size="small" onClick={() => history.push(`/projectDetail/${value}`)}>
                                  View
                                </Button>
																<Button sx={{mx: 2}} variant="contained" size="small" color="secondary" onClick={() => history.push(`/EditProject/${value}`)}>
                                  Edit
                                </Button>
                            </TableCell>
                          ) :(
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* <div className="container" style={{ overflow: "auto" }}>
        <Card raised sx={{ maxHeight: 100, py: 2 ,backgroundColor:"black",color:"#ffffff",position: "-webkit-sticky",
  position: "sticky",
  top: "0"}}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            // onClick={() => history.push(`/listingDetail/${d.id}`)}
          >
            <Grid item xs={2} lg={2}>
              Logo
            </Grid>
            <Grid item xs={3} lg={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Name
                </span>
                <span
                  style={{
                    fontSize: "0.8rem",
                    color: Colors.grey,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "50%",
                  }}
                >
                  Property Type
                </span>
              </div>
            </Grid>
            <Grid item xs={4} lg={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Price
                </span>
              </div>
            </Grid>
            <Grid item xs={1} lg={1}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Status
                </span>
              </div>
            </Grid>

            <Grid item xs={1} lg={1}>
              <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                Action
              </span>
            </Grid>
          </Grid>
        </Card>
        {allProjects.length > 0 ? (
          allProjects &&
          allProjects.map((d, index) => (
            <Card raised key={index} sx={{ maxHeight: 100, my: 2, py: 2 }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                // onClick={() => history.push(`/listingDetail/${d.id}`)}
              >
                <Grid item xs={2} lg={2}>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    src={"https://api.zeerac.com/" + d.logo}
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push(`/listingDetail/${d.id}`)}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {d.title}
                    </span>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: Colors.grey,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {d.property_type}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={4} lg={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: Colors.grey,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      <b> Minimum Price: </b>
                      {d.min_price}
                    </span>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: Colors.grey,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      <b> Maximum Price:</b> {d.max_price}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={1} lg={1}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {d.is_active_listing ? (
                      <Tooltip title="active">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: 5,
                            backgroundColor: "green",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Inactive">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: 5,
                            backgroundColor: "red",
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Grid>

                <Grid item xs={1} lg={1}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, d.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        // width: "20ch",
                        boxShadow: "0px 0px #ffffff",
                        border: "0.1px solid grey",
                        zIndex: 10,
                      },
                    }}
                  >
                    <MenuItem>visible/invisible</MenuItem>
                    <MenuItem
                      onClick={() => history.push(`/updatelisting/${dataV}`)}
                    >
                      edit button
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Card>
          ))
        ) : (
          <>
            <Loader />
          </>
        )}
      </div> */}
      </div>
    </Layout>
  );
};

export default AllProjects;

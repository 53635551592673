import AgencyArchive from "../sections/agency-archive/AgencyArchive";
import Layout from "../../customComponents/layout/Layout";
const Agencyarchive = () => {
  return (
    <Layout>
      <AgencyArchive />
    </Layout>
  );
};

export default Agencyarchive;

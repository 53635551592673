import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerWithCompany,
  registerWithoutCompany,
} from '../../../features/authSlice';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import TextTranslation from '../../constants/TextTranslation';
import PhoneNumberField from '../../miscellaneousComponents/PhoneNumberField';
import AutocompleteSearch from '../../layouts/HomeSearch/AutocompleteSearch';
import { values } from 'mobx';

const useStyles = makeStyles(() => ({
  profileMain: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  profileImg: {
    margin: 'auto',
    width: 70,
    height: 70,
    borderRadius: 35,
    border: '2px solid #014493',
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileUpload: {
    position: 'absolute',
    marginTop: 30,
    marginLeft: 35,
    color: '#fff',
    backgroundColor: '#014493',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: 1,
  },
  input: {
    height: 33,
    width: '100%',
    border: '2px solid #014493',
    borderRadius: 20,
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
    paddingLeft: 10,
    display: 'flex',
    margin: '5px 0',
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    '&::placeholder': {
      color: '#767676',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 11,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
  },
  error: {
    fontSize: 10,
    color: 'red',
    marginTop: -7,
    marginLeft: 5,
    fontFamily: 'Poopins-Regular',
  },
  textarea: {
    width: '100%',
    border: '2px solid #014493',
    borderRadius: 20,
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
    paddingLeft: 10,
    display: 'flex',
    margin: '5px 0',
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    '&::placeholder': {
      color: '#767676',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 11,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
  },
  btn: {
    width: 150,
    marginTop: 20,
    border: 'none',
    backgroundColor: '#014493',
    color: '#fff',
    cursor: 'pointer',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    borderRadius: 20,
  },
}));

const companyFormData = new FormData();
const CompanyDetail = ({ setNextTab }) => {
  const classes = useStyles();
  let history = useHistory();
  const [img, setImage] = React.useState('');
  const dispatch = useDispatch();
  const { values: formData, isSuccessCompany } = useSelector(
    (state) => state.auth);

  const userRegistrationResult = useSelector((state) => state.auth.userData);
  const companyRegistrationResult = useSelector(
    (state) => state.auth.companyData
  );
  const country = useSelector((state) => state.country.country);
  const lang = useSelector((state) => state.language);

  // regular expression for phone number
  const phoneRegExp = /^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{7})$/;

  // validation schema
  let Schema = Yup.object().shape({
    name: Yup.string().required(
      TextTranslation.companyNameRequired[lang.langIndex]
    ),
    email: Yup.string()
      .email(TextTranslation.invalidEmail[lang.langIndex])
      .required(TextTranslation.emailRequired[lang.langIndex]),
    description: Yup.string().required(
      TextTranslation.descriptionRequired[lang.langIndex]
    ),
    phone: Yup.string().matches(
      phoneRegExp,
      TextTranslation.phoneNumberNotvalid[lang.langIndex]
    ),
  });

  const customInputStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    paddingRight: 10,
  };

  const customErrorStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    marginRight: 5,
    fontSize: 10,
    color: 'red',
    marginTop: -7,
    marginLeft: 5,
    fontFamily: 'Poopins-Regular',
  };

  const handleImgUpload = (e) => {
    if (e.currentTarget.files[0]) {
      let file = e.currentTarget.files[0];
      setImage(file);
    }
  };
  useEffect(() => {
    if (userRegistrationResult?.status) {
      companyFormData.append('admin', userRegistrationResult?.result?.id);
      dispatch(registerWithCompany({data : companyFormData,token : userRegistrationResult?.result?.token }));
    } else {
      console.log('something wrong with user reg');
    }
  }, [userRegistrationResult]);
  useEffect(() => {
    if (isSuccessCompany) {
      if (companyRegistrationResult.status) {
        toast.success(companyRegistrationResult.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          progressStyle: { backgroundColor: '#014493' },
        });
        history.push('/signin');
      } else {
        toast.error(companyRegistrationResult.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [companyRegistrationResult, isSuccessCompany]);

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    city: '',
    fax: '',
    logo: '',
    description: '',
    admin: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={(values) => {
        dispatch(registerWithoutCompany(formData));
        companyFormData.append('name', values.name);
        companyFormData.append('email', values.email);
        companyFormData.append('phone', values.phone);
        companyFormData.append('city', values.city);
        companyFormData.append('fax', values.fax);
        companyFormData.append('logo', img);
        companyFormData.append('description', values.description);
      }}
    >
      {({ errors, handleChange, touched, handleBlur, isValid }) => (
        <Form>
          <Container>
            <Row className="justify-content-center">
              <div className={classes.profileMain}>
                {img === '' ? (
                  <PersonIcon sx={{ fontSize: 50, color: '#014493' }} />
                ) : (
                  <img
                    className={classes.profileImg}
                    src={URL.createObjectURL(img)}
                    alt={img ? 'profile-image' : null}
                  />
                )}

                <FileUploadOutlinedIcon
                  className={classes.fileUpload}
                  sx={{ fontSize: 18 }}
                />
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  accept="image/x-png,image/jpeg"
                  className="custom-file-input absolute-position"
                  onChange={(e) => {
                    handleImgUpload(e);
                  }}
                />
              </div>
            </Row>
            <Row
              className="justify-content-center"
              style={{
                display: 'flex',
                flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
              }}
            >
              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="name"
                  placeholder={TextTranslation.companyName[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.name && touched.name ? (
                  <div style={customErrorStyle}>{errors.name}</div>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <input
                  name="email"
                  placeholder={TextTranslation.companyEmail[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.email && touched.email ? (
                  <div style={customErrorStyle}>{errors.email}</div>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <PhoneNumberField
                  name="phone"
                  country={country}
                  placeholder={TextTranslation.phone[lang.langIndex]}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.phone && touched.phone ? (
                  <div style={customErrorStyle}>{errors.phone}</div>
                ) : null}
              </Col>

              <Col xs={12} sm={12} md={6} lg={6}>
                <AutocompleteSearch
                  name="city"
                  country={country}
                  searchOption={['(regions)']}
                  customStyle={classes.input}
                  style={customInputStyle}
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={TextTranslation.city[lang.langIndex]}
                />
                {errors.city && touched.city ? (
                  <div style={customErrorStyle}>{errors.city}</div>
                ) : null}
              </Col>

              <Col xs={12} sm={12} md={6} lg={6}>
                <input
                  name="fax"
                  placeholder={TextTranslation.companyFax[lang.langIndex]}
                  className={classes.input}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <textarea
                  name="description"
                  placeholder={
                    TextTranslation.companyDescription[lang.langIndex]
                  }
                  className={classes.textarea}
                  style={customInputStyle}
                  onBlur={handleBlur}
                  rows={3}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.description && touched.description ? (
                  <div style={customErrorStyle}>{errors.description}</div>
                ) : null}
              </Col>
            </Row>

            <Row
              className="justify-content-around"
              style={{
                display: 'flex',
                flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
              }}
            >
              <button
                className={classes.btn}
                onClick={() => setNextTab((prev) => prev - 1)}
              >
                {TextTranslation.goBack[lang.langIndex]}
              </button>
              <button className={classes.btn} type="submit" disabled={!isValid}>
                {TextTranslation.saveAndProceed[lang.langIndex]}
              </button>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyDetail;

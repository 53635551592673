import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { auth, db } from '../../../firebase';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setDoc, doc, Timestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { registerWithoutCompany, user } from '../../../features/authSlice';
import AutocompleteSearch from '../../layouts/HomeSearch/AutocompleteSearch';
import MultiLocationsAutoComplete from '../../miscellaneousComponents/MultiLocationsAutoComplete';
import TextTranslation from '../../constants/TextTranslation';

let Schema = Yup.object().shape({
  // area: Yup.string().required("Area Required"),
  city: Yup.string().required('City Required'),
  address: Yup.string().required('Address Required'),
});

const useStyles = makeStyles(() => ({
  input: {
    height: 33,
    width: '100%',
    border: '2px solid #014493',
    borderRadius: 20,
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
    paddingLeft: 10,
    display: 'flex',
    margin: '5px 0',
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    '&::placeholder': {
      color: '#767676',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 11,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
  },
  error: {
    fontSize: 10,
    color: 'red',
    marginTop: -7,
    marginLeft: 5,
    fontFamily: 'Poopins-Regular',
  },
  agency: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
  },
  btn: {
    width: 150,
    marginTop: 20,
    border: 'none',
    backgroundColor: '#014493',
    color: '#fff',
    cursor: 'pointer',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    borderRadius: 20,
  },
}));

const AddressInfo = ({ setNextTab, agent, setAgent }) => {

  console.log("this the selection of the agent",agent)
  const classes = useStyles();
  let history = useHistory();

  const initialValues = {
    city: '',
    area: '',
    address: '',
    isAgent: false,
  };

  const dispatch = useDispatch();
  const { values: formData, isSuccess } = useSelector((state) => state.auth);
  const country = useSelector((state) => state.country.country);

  const userRegistrationResult = useSelector((state) => state.auth.userData);

  const lang = useSelector((state) => state.language);

  const username = formData?.getAll('username')[0];
  const emailForFirebase = formData?.getAll('email')[0];
  const passwordForFirebase = formData?.getAll('password')[0];

  const [areaInfo, setAreaInfo] = useState([]);
  const [cityInput, setCityInput] = useState('');
  const [addressInput, setAddressInput] = useState('');

  const customInputStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    paddingRight: 10,
  };

  const customErrorStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    marginRight: 5,
    fontSize: 10,
    color: 'red',
    marginTop: -7,
    marginLeft: 5,
    fontFamily: 'Poopins-Regular',
  };

  const signInWithFirebase = async () => {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        emailForFirebase,
        passwordForFirebase
      );

      await setDoc(doc(db, 'users', result?.user?.uid), {
        uid: result?.user?.uid,
        username,
        emailForFirebase,
        createdAt: Timestamp.fromDate(new Date()),
        isOnline: true,
      });
      localStorage.setItem('@firebaseEmail', emailForFirebase);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (formData?.get) {
      let _area = formData
        ?.get('area')
        ?.split(',')
        ?.map((elem) => {
          return { description: elem };
        });
      // console.log({ _area });
      setAreaInfo(_area || []);
      setAgent(formData?.get('isAgent') ? true : false);
      setCityInput(formData?.get('city') || '');
      setAddressInput(formData?.get('address') || '');
    }
  }, [formData]);

  useEffect(() => {
    if (isSuccess) {
      if (userRegistrationResult.status) {
        toast.success(userRegistrationResult.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          progressStyle: { backgroundColor: '#014493' },
        });
        history.push('/signin');
      } else {
        toast.error(userRegistrationResult.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [userRegistrationResult, isSuccess]);
  const customValidation = (values) => {
    const errors = {};
    if (values.area === '' && !areaInfo.length)
      errors.area = 'area is Required';
    if (values.city === '' && cityInput === '')
      errors.city = 'city is Required';
    if (values.address === '' && addressInput === '')
      errors.address = 'Last name is Required';

    // console.log({ values, inputValues, errors });
    return errors;
  };
  const onFormSubmit = (values, actions) => {
    // console.log({ values, actions });
    let areaValuesArray = [];
    areaInfo.forEach((elem) => {
      areaValuesArray.push(elem?.description);
    });
    formData.append('city', cityInput);
    formData.append('area', areaValuesArray);
    formData.append('address', addressInput);
    formData.append('isAgent', agent);
    if (agent) {
      dispatch(user(formData));
      setNextTab(2);
      // dispatch(registerWithoutCompany(formData));
    } else {
      // SignInWithGoogle(formData.email, formData.password);
      dispatch(registerWithoutCompany(formData));
      signInWithFirebase();
      { userRegistrationResult.isSuccess
          ? toast.success(userRegistrationResult.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              progressStyle: { backgroundColor: '#014493' },
            })
          : toast.error(userRegistrationResult.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={Schema}
      validate={customValidation}
      onSubmit={onFormSubmit}
    >
      {({ errors, handleChange, touched, handleBlur, isValid }) => (
        <Form>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} sm={12} md={4} lg={6}>
                <AutocompleteSearch
                  name="city"
                  country={country}
                  searchOption={['(regions)']}
                  customStyle={classes.input}
                  style={customInputStyle}
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={TextTranslation.city[lang.langIndex]}
                  value={cityInput}
                  setValue={setCityInput}
                />
                {errors.city && touched.city ? (
                  <div style={customErrorStyle}>{errors.city}</div>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={4} lg={6}>
                <MultiLocationsAutoComplete
                  name="area"
                  placeholder={TextTranslation.area[lang.langIndex]}
                  style={customInputStyle}
                  value={areaInfo}
                  setValue={setAreaInfo}
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  country={country}
                  searchOption={['sublocality']}
                />

                {errors.area && touched.area ? (
                  <div style={customErrorStyle}>{errors.area}</div>
                ) : null}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} sm={12} md={4} lg={12}>
                <AutocompleteSearch
                  name="address"
                  placeholder={TextTranslation.address[lang.langIndex]}
                  country={country}
                  searchOption={['address']}
                  customStyle={classes.input}
                  style={customInputStyle}
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  value={addressInput}
                  setValue={setAddressInput}
                />
                {errors.address && touched.address ? (
                  <div style={customErrorStyle}>{errors.address}</div>
                ) : null}
              </Col>
            </Row>
            <div className="agency">
              <input
                name="isAgent"
                type="checkbox"
                className={classes.radio}
                checked={agent}
                style={customInputStyle}
                onChange={(e) => {
                  handleChange(e);
                  setAgent(!agent);
                }}
              />
              &nbsp;
              <span className={classes.text}>
                {TextTranslation.signupAsAgeny[lang.langIndex]}
              </span>
            </div>

            <Row
              className="justify-content-around"
              style={{
                display: 'flex',
                flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
              }}
            >
              <button
                className={classes.btn}
                onClick={() => setNextTab((prev) => prev - 1)}
              >
                {TextTranslation.goBack[lang.langIndex]}
              </button>
              <button className={classes.btn} type="submit" disabled={!isValid}>
                {agent
                  ? TextTranslation.saveAndProceed[lang.langIndex]
                  : TextTranslation.saveAndRegister[lang.langIndex]}
              </button>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default AddressInfo;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../../customComponents/layout/Layout";
import LearnCourse from "./LearnCourse";
import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom";
const Course = () => {
  let history = useHistory();
  const [gettoken, settoken] = useState("");
  useEffect(() => {
    settoken(localStorage.getItem("@token"));
  }, []);
  const getCourse = () => {
    const fetchData = axios
      .get(`https://api.zeerac.com/users/course/performance/`, {
        headers: { Authorization: `token ${gettoken}` },
      })
      .then((res) => console.log("res dataxxxxxxxx of the video", res));
  };
  useEffect(() => {
    getCourse();
  }, [gettoken]);
  return (
    <Layout>
      <Button className="btn btn-primary mt-3 me-5" onClick={() => history.push(`/add-new-course`)}style={{float:"right"}}>Add New Course</Button>
      <LearnCourse />
    </Layout>
  );
};

export default Course;

import React, { useEffect, useState } from 'react';

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const AutocompleteSearch = ({
  name,
  searchOption,
  customStyle,
  handleChange,
  placeholder,
  dontRestrict,
  country,
  value,
  setValue,
  style,
  defaultValue,
}) => {
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      types: searchOption,
      componentRestrictions: dontRestrict ? {} : { country },
      fields: ['formatted_address'],
    },
  });
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (placePredictions.length) setSuggestions(placePredictions);
  }, [placePredictions]);

  // useEffect(() => {
  //   console.log({ suggestions });
  // }, [suggestions]);

  return (
    <>
      <input
        type="text"
        className={customStyle}
        style={style}
        key={name}
        list={name}
        name={name}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder || ''}
        onChange={(e) => {
          getPlacePredictions({ input: e.target.value });
          handleChange(e);
          if (setValue) {
            setValue(e.target.value);
          }
        }}
      />

      <datalist id={name}>
        {suggestions?.map((item, i) => (
          <option key={i}>
            {item?.terms[dontRestrict ? item.terms.length - 1 : 0]?.value || ''}
          </option>
        ))}
      </datalist>
    </>
  );
};

export default AutocompleteSearch;

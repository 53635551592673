import React from 'react'
import Layout from '../../customComponents/layout/Layout'
import CustomStepper from '../../customComponents/customStepper/CustomStepper'
import { Typography } from '@mui/material'

const UpdateListing = () => {
  return (
    <Layout>
      <Typography component="div" sx={{ p: 2 }}>
        <CustomStepper />
      </Typography>
    </Layout>
  )
}
export default UpdateListing

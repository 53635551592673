import React from 'react';
import Colors from '../../config/Colors';
import { CircularProgress, Grid } from '@mui/material';

const Loader = () => {
  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '80vh' }}
    >
      <Grid>
        <CircularProgress
          style={{ color: '#014493' }}
          size={50}
          thickness={3}
        />
      </Grid>
    </Grid>
  );
};

export default Loader;

import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Layout from "../../customComponents/layout/Layout";
import {
  getProjectDetail,
  fetchAsyncPropjectDetail,
} from "../../features/store/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectSlickSlider from "./ProjectSlickSlider";
const ProjectDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncPropjectDetail(id));
  }, []);

  const projectDetail = useSelector(getProjectDetail);
  console.log("data", projectDetail);

  console.log("this is the projects", id);

  return (
    <Layout>
      <h1 style={{ textAlign: "center", paddingTop: "10px", fontWeight: 900 }}>
        Property Detail
      </h1>
      <div
        className="container"
        style={{
          padding: "2rem",
          borderRadius: 10,
          marginTop: "1rem",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            backgroundColor: "#eceff6",
            borderRadius: 10,
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ paddingBottom: "24px", paddingRight: "24px" }}
          >
            <img
              src={`https://api.zeerac.com/` + projectDetail?.logo}
              alt="logo"
              style={{ objectFit: "cover", width: "100%", borderRadius: 10 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <h1>{projectDetail?.title}</h1>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Property Type: </b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.property_type}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Area </b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.lat}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Country: </b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.country}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Property Type: </b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.property_type}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>City</b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.city}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Minimum Price: </b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.min_price}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Maximum Price: </b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.max_price}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Locality</b>
              </Grid>
              <Grid item xs={8}>
                {projectDetail?.locality}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs={4}>
                <b>Web link: </b>
              </Grid>
              <Grid item xs={8}>
                <a
                  href={`${projectDetail?.property_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {projectDetail?.property_url}
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <h1 style={{ textAlign: "center", padding: "10px 0" }}>Content</h1>
        <div style={{ display: "flex",justifyContent:"space-between",flexWrap:"wrap"}}>
          {projectDetail &&
            projectDetail.content &&
            projectDetail?.content?.map((item) => {
              return (
                <div style={{ width: "45%",maxWidth:"600px",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",padding:"30px"}}>
                  <ProjectSlickSlider data={item.files} />
                  <h3>{item?.title}</h3>
                  <p  className="contact-box">{item?.body}</p>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default ProjectDetail;

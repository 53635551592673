import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import AgencyBreadcrum from './AgencyBreadcrum';
import AgencyContact from './AgencyContact';
import AgencyListings from './AgencyListings';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  main: {
    height: '80vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: '100%',
  },
}));

const AgencyDetail = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.language);

  return (
    <div className={classes.main}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={10} sm={10} md={8} lg={8}>
          <AgencyBreadcrum />
          <Divider sx={{ background: '#014493' }} />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={'center'}
        spacing={2}
        display="flex"
        flexDirection={lang.langIndex === 2 ? 'row-reverse' : 'row'}
      >
        <Grid item xs={10} sm={10} md={6} lg={5} xl={5}>
          <PersonalInfo />
        </Grid>

        <Grid item xs={10} sm={10} md={3} lg={3}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              <AgencyContact />
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={10} sm={10} md={8} lg={8}>
          <AgencyListings />
        </Grid>
      </Grid>
    </div>
  );
};

export default AgencyDetail;

import AgencyDetail from "../sections/agency-details/AgencyDetail";
import Layout from "../../customComponents/layout/Layout";
const Agencydetails = () => {
  return (
    <Layout>
      <AgencyDetail />
    </Layout>
  );
};

export default Agencydetails;

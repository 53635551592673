import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  FormControl,
  OutlinedInput,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router";
import { Formik, Form } from "formik";
import CustomField from "../../customComponents/customField/CustomField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncGetPropertyDetail,
  updateProperty,
  getPropertyDetailForUpdate,
  postAsyncUpdatePropertyDetail,
} from "../../features/store/property/propertySlice";
import { baseUrl } from "./../constants/baseUrls";

// styles
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "5% 10% 0% 10%",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
}));

const BasicInfo = () => {
  const dispatch = useDispatch();
  const data = useSelector(getPropertyDetailForUpdate);
  let paramFromDetailListing = useParams();
  const classes = useStyles();
  const [image, setImage] = useState();

  console.log("value", data.status);
  // get data to show in fields

  const handleImgUpload = (e) => {
    if (e.currentTarget.files[0]) {
      let file = e.currentTarget.files[0];
      setImage(file);
    }
  };
  const getUpdateData = async (values) => {
    const id = paramFromDetailListing.id;
    const val = { values, id };
    dispatch(postAsyncUpdatePropertyDetail(val));

    var bodyformData = new FormData();
    bodyformData.append("thumbnail", image);
    await axios({
      method: "PUT",
      url: `${baseUrl}/users/property/${id}/`,
      data: bodyformData,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      console.log("response", res);
    });
  };

  useEffect(() => {
    dispatch(fetchAsyncGetPropertyDetail(paramFromDetailListing.id));
  }, []);

  return (
    <Container fullWidth>
      <Formik
        initialValues={{
          name: data.name,
          //type: data?.user?.type,
          type: "",
          status: data.is_active_listing,
          price: data.price,
          unit: data.unit,
          space: data.space,
          description: data.description,
          video: data.video,
        }}
        onSubmit={(values) => {
          getUpdateData(values);
        }}
      >
        {({ handleChange, handleBlur }) => (
          <Typography component="div" sx={{ width: "100%" }}>
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <label>Name</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Property Name"
                      name="name"
                      defaultValue={data.name}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <select
                      name="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="DEFAULT">
                        {data.type ? data.type : "Property Type"}
                      </option>
                      <optgroup label="Commercial">
                        <option value={3}>Shops</option>
                        <option value={4}>Buildings</option>
                        <option value={5}>Offices</option>
                        <option value={6}>Plots</option>
                      </optgroup>
                      <optgroup label="Residential">
                        <option value={7}>Houses</option>
                        <option value={8}>Flats</option>
                        <option value={9}>Upper Portion</option>
                        <option value={10}>Single Story</option>
                      </optgroup>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <select
                      name="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="DEFAULT">
                        {data.status ? data.status : "Property Status"}
                      </option>
                      <option value="Featured">Featured</option>
                      <option value="For Rent">For Rent</option>
                      <option value="For Sale">For Sale</option>
                      <option value="Leased">Leased</option>
                      <option value="New Addition">New Addition</option>
                      <option value="Sold">Sold</option>
                      <option value="Rental">Rental</option>
                      <option value="Reduced">Reduced</option>
                      <option value="Special Offer">Special Offer</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <label>Property Price</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="price"
                      label="Property Price"
                      type="number"
                      defaultValue={data.price}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <label>Property Space Unit</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Property Space Unit"
                      name="unit"
                      defaultValue={data.unit}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <label>Property Space (sqft)</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="space"
                      defaultValue={data.space}
                      type="number"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <label>Property Description</label>
                    <input
                      multiline
                      minRows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description"
                      defaultValue={data.description}
                    />
                  </div>
                </Grid>
               
                <Grid item xs={6}>
                  <div className="profileEditField">
                    <label>Logo</label>

                    <input
                      type="file"
                      name="thumbnail"
                      className="custom-file-input"
                      id="thumbnail"
                      accept="image/x-png,image/jpeg"
                      onChange={handleImgUpload}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="profileEditField">
                    <label>Property Video URL</label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="video"
                      defaultValue={data.video}
                  
                    />
                  </div>
                </Grid>
              </Grid>

                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Button variant="outlined" type="submit">
                  Update
                </Button>
              </div>
            </Form>
          </Typography>
        )}
      </Formik>
    </Container>
  );
};

export default BasicInfo;

import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/system';
import {DEFAULT_AVATAR } from '../../constants/global';
import { baseUrl } from '../../constants/baseUrls';
import AgencyFilter from './AgencyFilter';
import PreLoder from '../../layouts/Preloader';
import { agency } from '../../../features/agencySlice';
import { useDispatch, useSelector } from 'react-redux';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import TextTranslation from '../../constants/TextTranslation';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { deleteAgencyfromDatabase } from '../../../api/dataApi';
const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
});

const useStyles = makeStyles(() => ({
  body: {
    //height: '80vh',
    margin: 'auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  // listMain: {
  //   marginTop: '6%',
  //   [theme.breakpoints.down('lg')]: {
  //     marginTop: '7%',
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     marginTop: '10%',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     marginTop: '20%',
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: '25%',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     marginTop: '15%',
  //   },
  // },
  thumbnail: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.7,
  },
  name: {
    fontSize: 10,
    color: '#949ea9',
    fontFamily: 'Poopins-Regular',
    textTransform: 'capitalize',
  },
  cityName: {
    fontSize: 10,
    color: '#949ea9',
    fontFamily: 'Poopins-Regular',
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  value: {
    fontSize: 12,
    color: '#014493',
    fontFamily: 'Poopins-SemiBold',
  },
  viewProfile: {
    color: '#fff',
    backgroundColor: '#014493',
    border: 'none',
    borderRadius: 20,
    fontSize: 11,
    fontFamily: 'Poopins-Regular',
    cursor: 'pointer',
    width: 100,
    textAlign: 'center',
  },
  deleteProfile: {
    color: '#fff',
    backgroundColor: '#dd2929',
    border: 'none',
    borderRadius: 20,
    padding:1,
    fontSize: 11,
    fontFamily: 'Poopins-Regular',
    textDecoration:'none',
    cursor: 'pointer',
    width: 100,
    fontWeight:"bold",
    textAlign: 'center',
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 10,
    backgroundColor: '#d5d5d5',
    margin: 'auto',
  },
  viewMore: {
    color: '#014493',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  smallScreen: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  noResults: {
    color: '#014493',
    fontFamily: 'Poopins-Bold',
    fontSize: 30,
    textAlign: 'center',
    marginTop: '20%',
  },
}));

const AgencyArchive = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.agency);
  const lang = useSelector((state) => state.language);

  const [agencyName, setAgencyName] = useState('');
  const [area, setArea] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (data?.data?.result?.count) {
      setFilteredData(data?.data?.result);
      const rem = data?.data?.result?.count % 10;
      let quo = ~~(data?.data?.result?.count / 10);
      if (rem > 0) {
        quo = quo + 1;
      }
      setPageCount(quo);
    }
  }, [data]);

  const callPaginationApi = (currentPage) => {
    axios(`${baseUrl}/users/company/?page=${currentPage}`, {
      method: 'GET',
    })
      .then((res) => {
        setFilteredData(res.data.result.results);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    callPaginationApi(currentPage);
  };

  const ReduxData = async () => {
    await dispatch(agency(data));
  };

  useEffect(() => {
    ReduxData();
  }, []);

  useEffect(() => {
    // customLog({ data });
    setFilteredData(data?.data?.result?.results);
  }, [data]);

  const filterSearchResults = () => {
    if (agencyName === '' && area === '') {
      setFilteredData(data?.data?.result?.results);
    }
    if (agencyName !== '')
      setFilteredData(
        data?.data?.result?.results.filter((e) =>
          e?.name?.toLowerCase().includes(agencyName.toLowerCase())
        )
      );
    if (area !== '')
      setFilteredData(
        data?.data?.result?.results.filter((e) =>
          e?.address?.toLowerCase().includes(area.toLowerCase())
        )
      );
  };
  useEffect(() => {
    filterSearchResults();
  }, [agencyName, area]);

  const handleReset = (e) => {
    e.preventDefault();
    setAgencyName('');
    setArea('');
  };
  return (
    <>
      {isLoading ? (
        <PreLoder />
      ) : (
        <>
          <div className={classes.body}>
            <AgencyFilter
              setAgencyName={setAgencyName}
              agencyName={agencyName}
              setCity={setArea}
              city={area}
              handleSearch={(e) => {
                e.preventDefault();
                filterSearchResults();
              }}
              handleReset={handleReset}
            />
            <div className={classes.listMain}>
              {filteredData && filteredData.length ? (
                filteredData?.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    sx={{
                      alignItems: 'center',
                      border: 'none',
                      direction: lang.langIndex === 2 ? 'rtl' : 'ltr',
                      boxShadow:
                        index % 2 == 0
                          ? 'rgba(149, 157, 165, 0.4) 0px 8px 24px'
                          : 'none',
                      backgroundColor: index % 2 == 0 ? '#daedff' : '#fff',
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                      <div className={classes.smallScreen}>
                        <StarOutlineIcon
                          sx={{
                            color: '#014493',
                            ml: lang.langIndex === 2 ? null : 2,
                            mr: lang.langIndex === 2 ? 2 : null,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1}>
                      <div className={classes.thumbnail}>
                        <img
                          src={
                            item?.logo
                              ? `${baseUrl}/${item.logo}`
                              : DEFAULT_AVATAR
                          }
                          alt={item?.name}
                          className={classes.thumbnail}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2.5} sm={2} lg={1.5}>
                      <div className={classes.group}>
                        <span className={classes.name}>
                          {TextTranslation.name[lang.langIndex]}
                        </span>
                        <span className={classes.value}>{item?.name}</span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      sm={2}
                      lg={2}
                      className={classes.smallScreen}
                    >
                      <div className={classes.group}>
                        <span className={classes.name}>
                          {TextTranslation.area[lang.langIndex]}
                        </span>
                        <span className={classes.value}>{item?.address}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4.5} sm={4} md={3} lg={2}>
                      <div className={classes.group}>
                        <span className={classes.name}>
                          {TextTranslation.email[lang.langIndex]}
                        </span>
                        <span className={classes.value}>{item?.email}</span>
                      </div>
                    </Grid>
                    <Grid item lg={0.7} className={classes.smallScreen}>
                      <div className={classes.group}>
                        <span className={classes.name}>
                          {TextTranslation.listings[lang.langIndex]}
                        </span>
                        <span className={classes.value}>
                          {item?.active_listings}
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={1}
                      className={classes.smallScreen}
                    >
                      <div className={classes.group}>
                        <span className={classes.name}>
                          {TextTranslation.listingsBy[lang.langIndex]}
                        </span>
                        <span className={classes.value}>
                          {item?.admin?.username}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1}>
                      <Link to={`/agency-detail/${item.id}`}>
                        <div className={classes.viewProfile}>
                          {TextTranslation.viewProfile[lang.langIndex]}
                        </div>
                      </Link>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1}>
                        <div className={classes.deleteProfile} onClick={()=>{
                          deleteAgencyfromDatabase(item.id)
                        }}>
                          Delete
                        </div>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <div className={classes.noResults}>
                  {TextTranslation.noResultsFound[lang.langIndex]}
                </div>
              )}
            </div>
          </div>
          {filteredData && (
            <div className="pagination justify-content-center mt-4">
              <ReactPaginate
                previousLabel={<ChevronLeftIcon className={classes.icon} />}
                nextLabel={<ChevronRightIcon className={classes.icon} />}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AgencyArchive;

const Colors = {
  // primary: ['#899FD4 0%', '#A389D4 100%'],
  primary: '#014493',
  secondary: ['#1de9b6 0%', '#1dc4e9 100%'],
  purple: '#A389D4',
  seagreen: '#1de9b6',
  darkgrey: '#3f4d67',
  black: '#262626',
  white: '#fff',
  grey: '#6e6969',
  lightgrey: '#d3d3d3',
  danger: '#ff5252',
  green: '#008000',
  // blue: '#2471a3',
  blue: '#4169E1',
  lightblue: '#bbe6f4',
};

export default Colors;

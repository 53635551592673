import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import TextTranslation from '../../constants/TextTranslation';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  icon: {
    color: '#014493',
  },
  text: {
    color: '#014493',
    fontSize: 12,
    fontFamily: 'Poopins-Regular',
  },
  active: {
    color: '#014493',
    fontSize: 12,
    fontFamily: 'Poopins-Bold',
  },
}));

const AgentBreadcrum = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.language);

  const customMainStyle = {
    display: 'flex',
    flexDirection: lang.langIndex === 2 ? 'row-reverse' : 'row',
    alignItems: 'center',
    margin: '10px 0',
  };

  return (
    <div style={customMainStyle}>
      <HomeIcon className={classes.icon} />
      {lang.langIndex === 2 ? (
        <ArrowLeftIcon className={classes.icon} />
      ) : (
        <ArrowRightIcon className={classes.icon} />
      )}

      <span className={classes.text}>
        {TextTranslation.agents[lang.langIndex]}
      </span>
      {lang.langIndex === 2 ? (
        <ArrowLeftIcon className={classes.icon} />
      ) : (
        <ArrowRightIcon className={classes.icon} />
      )}

      <span className={classes.active}>
        {TextTranslation.agentsProfile[lang.langIndex]}
      </span>
    </div>
  );
};

export default AgentBreadcrum;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../components/constants/baseUrls";


export const fetchAsyncProperties = createAsyncThunk('listings/fetchAsyncProperties', async () => {
    const response = await axios.get(`${baseUrl}/users/property`)
    return response.data.result.results
})

export const fetchAsyncProperty = createAsyncThunk('listing/fetchAsyncProperty', async(propertyId)=>{
    const response = await axios.get(`${baseUrl}/users/property/${propertyId}`)
    return response.data.result
})

export const fetchAsyncGetPropertyDetail = createAsyncThunk('updateProperty/fetchAsyncGetPropertyDetail', async(propertyDetailListingID)=>{
    const response =  await axios({
        method: "GET",
        url: `${baseUrl}/users/property/${propertyDetailListingID}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
    return response.data.result
})

export const postAsyncUpdatePropertyDetail = createAsyncThunk('updateProperty/postAsyncUpdatePropertyDetail', async({values,id})=>{
    // let formData = new FormData();
    // Object.keys(values).map(function (key) {
    //   formData.append(key, values[key]);
    //   return formData;
    // });
    
    await axios({
        method: "PUT",
        url: `${baseUrl}/users/property/${id}/`,
        data: values,
        headers: {
          'Content-Type': 'application/json',
        },
      })
})
 

export const fetchAsyncPropjects = createAsyncThunk('listings/fetchAsyncPropjects', async () => {
    const response = await axios.get(`${baseUrl}/users/project/`)
    return response.data.result
})

export const fetchAsyncAgents = createAsyncThunk('agerts/fetchAsyncAgents', async () => {
    const response = await axios.get(`${baseUrl}/users/agents/`)
    return response.data.result
})

export const fetchAsyncPropjectDetail = createAsyncThunk('listings/fetchAsyncPropjectDetail', async (id) => {
    const response = await axios.get(`${baseUrl}/users/project/${id}`)
    return response.data.result
})

const initialState = {
     // listings  data
     listings: [],

     // listing  data Detail
     listing: [],
     
     // test data state for Add New Project
     data: '',

     // edit property detail
     updateProperty: '',

     // get all detail
     allProjects: [],

     // get project detail
     projectDetail:[],

     // get all agents
     allAgents:[],
}
const propertySlice = createSlice({
	name: "properties",
	initialState,
	reducers: {
        allProperty: (state, { payload }) => {
            state.listings = payload
        },	

        propertyDetail: (state, { payload }) => {
            state.listing = payload
        },
        addNewProperty: (state, { payload }) => {
            state.data = payload
        },
        updateProperty: (state, { payload }) => {
            state.updateProperty = payload
        },
        allAgents: (state, { payload }) => {
            state.allAgents = payload
        },
	},
    extraReducers: {

        [fetchAsyncProperties.pending]: () => {
           
        },

        [fetchAsyncProperties.fulfilled]: (state, { payload }) => {
            
            return { ...state, listings: payload };
            
        },

        [fetchAsyncProperties.rejected]: () => {
        
        },

        //property Detial data
        [fetchAsyncProperty.pending]: () => {
            
        },

        [fetchAsyncProperty.fulfilled]: (state, { payload }) => {
           
            return { ...state, listing: payload };
            
        },

        [fetchAsyncProperty.rejected]: () => {
      
        },

        //property Detial data for update
        [fetchAsyncGetPropertyDetail.pending]: () => {
            
        },

        [fetchAsyncGetPropertyDetail.fulfilled]: (state, { payload }) => {
            
            return { ...state, updateProperty: payload };
            
        },

        [fetchAsyncGetPropertyDetail.rejected]: () => {
          
        },

         //set all project data
         [fetchAsyncPropjects.pending]: () => {
            
        },

        [fetchAsyncPropjects.fulfilled]: (state, { payload }) => {
            
            return { ...state, allProjects: payload };
            
        },

        [fetchAsyncPropjects.rejected]: () => {
          
        },
         //set all project data
         [fetchAsyncPropjectDetail.pending]: () => {
            
        },

        [fetchAsyncPropjectDetail.fulfilled]: (state, { payload }) => {
            
            return { ...state, projectDetail: payload };
            
        },

        [fetchAsyncPropjectDetail.rejected]: () => {
          
        },
         //get all agents data
         [fetchAsyncAgents.pending]: () => {
            
        },

        [fetchAsyncAgents.fulfilled]: (state, { payload }) => {
            
            return { ...state, allAgents: payload };
            
        },

        [fetchAsyncAgents.rejected]: () => {
          
        },
    }
});

export const {allProperty,propertyDetail,addNewProperty,updateProperty} = propertySlice.actions;
export const getAllproperties = (state) => state.properties.listings
export const getPropertyDetail = (state) => state.properties.listing
export const getRecentlyAddProperty = (state) => state.properties.data
export const getPropertyDetailForUpdate = (state) => state.properties.updateProperty
export const getAllProjects = (state) => state.properties.allProjects
export const getProjectDetail = (state) => state.properties.projectDetail
export const getAllAgents = (state) => state.properties.allAgents






export default propertySlice.reducer;



import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, Avatar, Typography, Box, Modal } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Layout from '../../customComponents/layout/Layout';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import BedSharpIcon from '@mui/icons-material/BedSharp';
import BathtubSharpIcon from '@mui/icons-material/BathtubSharp';
import BorderInnerSharpIcon from '@mui/icons-material/BorderInnerSharp';
import HomeWorkSharpIcon from '@mui/icons-material/HomeWorkSharp';
import HouseIcon from '@mui/icons-material/House';
import WaterIcon from '@mui/icons-material/Water';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CustomAccordian from '../../customComponents/customAccordian/CustomAccordian';
import Loader from '../../customComponents/loader';
import Colors from '../../config/Colors';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';

import { useDispatch, useSelector } from 'react-redux';
import {
  getPropertyDetail,
  fetchAsyncProperty,
} from '../../features/store/property/propertySlice';

const AutoPlaySwipeableViews = SwipeableViews;

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const ListingDetail = () => {
  const dispatch = useDispatch();
  const propertyDetail = useSelector(getPropertyDetail);

  let paramFromListingTable = useParams();
  let history = useHistory();

  const [image, setImage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false); // modal state
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = image.length;
  const theme = useTheme();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    dispatch(fetchAsyncProperty(paramFromListingTable.id));

    return () => {
      setImage([]);
    };
  }, []);

  return (
    <Layout>
      {!loader ? (
        <>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <AutoPlaySwipeableViews
                autoPlay={false}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {image.map((step, index) => (
                  <div key={index}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: 500,
                        }}
                        component="img"
                        src={'https://api.zeerac.com/' + step.image}
                        alt=""
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </Modal>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <Grid item>
              <Typography
                component="h4"
                variant="h4"
                sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
              >
                {propertyDetail.name}
              </Typography>
              <Typography component="div" sx={{ color: Colors.grey, mb: 2 }}>
                <span>
                  <LocationOnSharpIcon fontSize="small" />
                </span>
                <span className="mx-2">
                  <b>{propertyDetail.address}</b>
                </span>
              </Typography>
            </Grid>
            <Grid item display="flex">
              <Typography
                sx={{ mx: 3, fontWeight: 'bold', color: Colors.grey }}
                variant="h4"
              >
                Rs: &nbsp;{propertyDetail.price}
              </Typography>
              <Typography
                component="div"
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push(`/updatelisting/${paramFromListingTable.id}`)
                }
              >
                <EditIcon fontSize="medium" />
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-around" direction="row-reverse">
            <Grid item xs={12} sm={12} md={4}>
              <Card sx={{ p: 2, mb: 2 }} raised>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <span>
                    <Avatar
                      sx={{
                        width: 130,
                        height: 130,
                      }}
                      src={'https://api.zeerac.com/' + propertyDetail.thumbnail}
                    />
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ fontWeight: 'bold' }}
                    >
                      <span>{propertyDetail?.user?.first_name}</span>
                      &nbsp;
                      <span>{propertyDetail?.user?.last_name}</span>
                    </Typography>
                    <Typography
                      // variant="subtitle1"
                      component="div"
                      sx={{ color: Colors.grey }}
                    >
                      {propertyDetail?.user?.company}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ py: 1 }}>
                      <EmailIcon fontSize="small" />
                      &nbsp;{propertyDetail?.user?.email}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ py: 1 }}>
                      <PhoneIcon fontSize="small" />
                      &nbsp;{propertyDetail?.user?.phone_number}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>

            <Grid item md={7.5}>
              <ImageList
                variant="quilted"
                cols={4}
                rowHeight={100}
                onClick={handleOpen}
              >
                {image.map((item, index) => (
                  <ImageListItem
                    key={index}
                    cols={item.cols || 2}
                    rows={item.rows || 2}
                  >
                    <img
                      src={
                        'https://api.zeerac.com/' +
                        item.image +
                        '?w=164&h=164&fit=crop&auto=format&dpr=2 2x'
                      }
                      alt=""
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>

              <br />
              <CustomAccordian title="Overview">
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    my: 2,
                  }}
                  component="div"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      <BedSharpIcon fontSize="medium" />
                    </span>
                    <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {propertyDetail.beds}&nbsp;Bedrooms
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      <BathtubSharpIcon fontSize="medium" />
                    </span>
                    <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {propertyDetail.bathrooms}&nbsp;Bathrooms
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      <BorderInnerSharpIcon fontSize="medium" />
                    </span>
                    <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {propertyDetail.space}&nbsp;sqft
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      <HomeWorkSharpIcon fontSize="medium" />
                    </span>
                    <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      built In: &nbsp;{propertyDetail.year}
                    </span>
                  </div>
                </Typography>
              </CustomAccordian>
              <br />

              <CustomAccordian title="Description">
                {propertyDetail.description}
              </CustomAccordian>
              <br />

              <CustomAccordian title="Address">
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                  component="div"
                >
                  <div>
                    <b>City:&nbsp;</b>
                    {propertyDetail?.user?.city}
                  </div>
                  <div>
                    <b>Area:&nbsp;</b>
                    {propertyDetail?.user?.area}
                  </div>
                  <div>
                    <b>Address:&nbsp;</b>
                    {propertyDetail.address}
                  </div>
                </Typography>
              </CustomAccordian>

              <br />

              <CustomAccordian title="Features">
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  {propertyDetail?.features?.furnished && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <HouseIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Furnished
                      </span>
                    </div>
                  )}
                  {propertyDetail?.features?.parking && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <DirectionsCarIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Parking
                      </span>
                    </div>
                  )}
                  {propertyDetail?.features?.electricity && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <ElectricalServicesIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Electricity
                      </span>
                    </div>
                  )}

                  {propertyDetail?.features?.suigas && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <LocalFireDepartmentIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Suigas
                      </span>
                    </div>
                  )}
                  {propertyDetail?.features?.landline && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <LocalPhoneIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Landline
                      </span>
                    </div>
                  )}
                  {propertyDetail?.features?.sewerage && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <WaterIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Sewerage
                      </span>
                    </div>
                  )}
                  {propertyDetail?.features?.internet && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span>
                        <NetworkWifiIcon fontSize="large" />
                      </span>
                      <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Internet
                      </span>
                    </div>
                  )}
                </Typography>
              </CustomAccordian>
              <br />
              <CustomAccordian title="Details">
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      Id: &nbsp;
                    </span>
                    <span style={{ color: Colors.grey, fontSize: '1rem' }}>
                      {propertyDetail.id}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      Type: &nbsp;
                    </span>
                    <span style={{ color: Colors.grey, fontSize: '1rem' }}>
                      {propertyDetail.type}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      Price: &nbsp;
                    </span>
                    <span style={{ color: Colors.grey, fontSize: '1rem' }}>
                      Rs:&nbsp;{propertyDetail.price}
                    </span>
                  </div>
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      Size: &nbsp;
                    </span>
                    <span style={{ color: Colors.grey, fontSize: '1rem' }}>
                      {propertyDetail.space}sqft
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      Condition: &nbsp;
                    </span>
                    <span style={{ color: Colors.grey, fontSize: '1rem' }}>
                      {propertyDetail.condition}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      Bedrooms: &nbsp;
                    </span>
                    <span style={{ color: Colors.grey, fontSize: '1rem' }}>
                      {propertyDetail.beds}
                    </span>
                  </div>
                </Typography>
              </CustomAccordian>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

export default ListingDetail;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../components/constants/baseUrls';
import axios from 'axios';

const initialState = {
  data: [],
  dataDetail: [],
  agent_Listings: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const agent = createAsyncThunk('agent/agent', async (data, thunkAPI) => {
  try {
    const response = await axios.get(baseUrl + '/users/agents/', data);
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const agentDetail = createAsyncThunk(
  'agent/agentDetail',
  async (id, data, thunkAPI) => {
    try {
      const response = await axios.get(baseUrl + `/users/agents/${id}`, data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agentListings = createAsyncThunk(
  'agent/agentListings',
  async (id, data, thunkAPI) => {
    try {
      const response = await axios.get(
        baseUrl + `/users/agent-listings/?agent=${id}`,
        data
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      //agent archive
      .addCase(agent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(agent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = [];
      })
      // agent detail
      .addCase(agentDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agentDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dataDetail = action.payload;
      })
      .addCase(agentDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dataDetail = [];
      })
      // agent listings
      .addCase(agentListings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agentListings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.agent_Listings = action.payload;
      })
      .addCase(agentListings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agent_Listings = [];
      });
  },
});

export const { reset } = agentSlice.actions;
export default agentSlice.reducer;

export default {
  // Country_Names
  country: ["Country", "ülke seç", "حدد الدولة"],
  language: ["Select Language", "Dil Seçin", "اختار اللغة"],
  countrySelection: [
    "select country you want to search for",
    "Aramak istediğiniz ülkeyi seçin",
    "حدد الدولة التي تريد البحث عنها",
  ],
  currentlyIn: ["Currently in", "şu anda", "حاليا في"],
  pakistan: ["Pakistan", "Pakistan", "باكستان"],
  turkey: ["Turkey", "Türkiye", "ديك رومى"],
  usa: ["U.S.A", "Amerika Birleşik Devletleri", "الولايات المتحدة الأمريكية"],
  uae: ["U.A.E", "Birleşik Arap Emirlikleri", "الإمارات العربية المتحدة"],
  france: ["France", "Fransa", "فرنسا"],
  india: ["India", "Hindistan", "الهند"],
  english: ["English", "ingilizce", "الإنجليزية"],
  urdu: ["Urdu", "Urduca", "الأردية"],
  hindi: ["Hindi", "Hintçe", "الهندية"],
  arabic: ["Arabic", "Arapça", "عربى"],
  french: ["French", "Fransızca", "الفرنسية"],
  turkish: ["Turkish", "Türkçe", "اللغة التركية"],
  // header
  home: ["Home", "ev", "مسكن"],
  projects: ["Projects", "Projeler", "المشاريع"],
  listings: ["Listings", "Listeler", "القوائم"],
  agents: ["Agents", "Ajanlar", "عملاء"],
  agency: ["Agency", "Ajans", "وكالة"],
  blogs: ["Blogs", "Bloglar", "المدونات"],
  trends: ["Trends", "trendler", "اتجاهات"],
  lookingFor: ["Looking For?", "Aramak?", "البحث عن؟"],
  login: ["Login", "giriş yapmak", "تسجيل الدخول"],
  signUp: ["Sign Up", "üye olmak", "اشتراك"],
  pages: ["Pages", "sayfalar", "الصفحات"],
  tutorials: ["Tutorials", "öğreticiler", "دروس"],

  notifications: ["Notifications", "bildirimler", "إشعارات"],
  sentYouMessage: [
    "sent you a message",
    "sana bir mesaj gönderdi",
    "ارسلت لك رساله",
  ],
  viewProfile: ["View Profile", "profili Görüntüle", "عرض الصفحة الشخصية"],
  editProfile: ["Edit Profile", "profili Düzenle", "تعديل الملف الشخصي"],
  editCompany: ["Edit Company", "şirketi düzenle", "تحرير الشركة"],
  myProfile: ["My Profile", "Benim profilim", "ملفي"],
  myPreferences: ["My Preferences", "Benim tercihlerim", "تفضيلاتي"],
  myRewards: ["My Rewards", "Ödüllerim", "مكافآتي"],
  // footer
  zeerac: ["Zeerac", "Zeerac", "زيراك"],
  buyAnywhereSellAnywhere: [
    "Buy Anywhere . Sell Anywhere",
    "Her Yerden Satın Alın. Her Yerde Satış",
    "شراء في أي مكان . بيع في أي مكان",
  ],
  copyright: [
    "All rights Reserved",
    "Tüm Hakları Saklıdır",
    "كل الحقوق محفوظة",
  ],
  headOffice: ["Head Office", "Merkez ofis", "المكتب الرئيسي"],
  headOfficeAddress: [
    "24-D 2nd Floor Rashid Plaza Main Jinnah Avenue G 6/3 Blue Area Islamabad",
    "24-D 2. Kat Rashid Plaza Main Jinnah Avenue G 6/3 Mavi Alan İslamabad",
    "الطابق الثاني راشد بلازا الرئيسي شارع جناح ج 6/3 المنطقة الزرقاء إسلام أباد",
  ],
  branchOffice: ["Branch Office", "Şube", "مكتب فرعي"],
  branchOfficeLahore: [
    "Plot # 349 Block G3 Johar Town",
    "Arsa # 349 Blok G3 Johar Kasabası",
    "قطعة رقم 349 بلوك G3 بلدة جوهر",
  ],
  branchOfficeUAE: [
    "Plot # 241-O Makani # 2920795818 /118-1177 Gragesh Center Naif Dubai UAE",
    "Arsa # 241-O Makani # 2920795818 /118-1177 Gragesh Centre Naif Dubai BAE",
    "241 قطعة أرض #-O مكاني # 2920795818 / 118-1177 Gragesh Center Naif Dubai UAE",
  ],
  logout: ["Logout", "çıkış Yap", "تسجيل خروج"],
  // Home
  searchListing: ["Search Listings", "Arama Listesi", "البحث عن القوائم"],
  submitListings: ["Submit Listings", "İlanları Gönder", "إرسال القوائم"],
  buy: ["Buy", "Satın almak", "يشتري"],
  rent: ["Rent", "Kiraya vermek", "تأجير"],
  // projects
  priceRange: ["Price Range", "Fiyat aralığı", "نطاق السعر"],
  actionSpeak: ["Action Speak", "eylem konuşması", "الكلام العمل"],
  louderWords: [
    "Louder Than Words",
    "kelimelerden daha yüksek sesle",
    "بصوت أعلى من الكلمات",
  ],
  overview: ["Overview", "genel bakış", "ملخص"],
  projectId: ["Project ID", "proje kimliği", "معرف المشروع"],
  price: ["Price", "fiyat", "سعر الحد الأدنى"],
  minimumPrice: ["Minimum Price", "Minimum fiyat", "سعر الحد الأدنى"],
  maximumPrice: ["Maximum Price", "Maksimum Fiyat", "السعر الأقصى"],
  locality: ["Locality", "yerellik", "المنطقة"],
  locationNearby: ["Location & Nearby", "Konum ve Yakın", "الموقع والجوار"],

  // Listings
  city: ["City", "Şehir", "مدينة"],
  area: ["Area", "Alan", "منطقة"],
  location: ["Location", "Konum", "موقع"],
  currentLocation: ["Current Location", "Mevcut Konum", "الموقع الحالي"],

  purpose: ["Purpose", "Amaç", "غاية"],
  propertyType: ["Property Type", "Emlak Tipi", "نوع الملكية"],
  beds: ["Beds", "Yataklar", "سرير"],
  and: ["&", "ve", "&"],
  baths: ["Baths", "Banyolar", "الحمامات"],
  moreFilters: ["More Filters", "Diğer Filtreler", "المزيد من المرشحات"],
  listingsFound: ["Listings Found", "İlan Bulundu", " القوائم وجدت"],
  showMap: ["Show Map", "Haritayı göster", "عرض الخريطة"],
  findNearMe: ["Find near me", "yakınımdaki bul", "تجد بالقرب مني"],

  propertyStatus: ["Property Status", "Mülk Durumu", "حالة الملكية"],
  bedrooms: ["Bedrooms", "Mülk Statüsü", "غرف نوم"],
  bathrooms: ["Bathrooms", "banyolar", "الحمامات"],
  space: ["Space", "Uzay", "مساحة"],
  neighborhood: ["Neighborhood", "Komşu", "حي"],
  condition: ["Condition", "Şart", "حالة"],
  builtIn: ["Built in", "yerleşik", "مدمج"],
  features: ["Features", "Özellikler", "سمات"],
  nearbyLocations: [
    "Nearby Locations",
    "yakındaki Konumlar",
    "المواقع المجاورة",
  ],
  recentListings: ["Recent Listings", "Son Listeler", "القوائم الأخيرة"],
  propertyDescription: [
    "Property Description",
    "Özellik Açıklaması",
    "وصف العقار",
  ],
  callAgent: ["Call Agent", "Temsilciyi ara", "وكيل الاتصال"],
  emailAgent: ["Email Agent", "E-posta Aracısı", "وكيل البريد الإلكتروني"],
  cancel: ["Cancel", "iptal", "يلغي"],
  send: ["Send", "Göndermek", "إرسال"],

  // Purpose
  forRent: ["For rent", "kiralık", "للإيجار"],
  forSale: ["For sale", "satılık", "للبيع"],
  forInstalment: ["For Installment", "Taksitli", "بالتقسيط"],

  // Property_type
  commercial: ["Commercial", "Reklam", "تجاري"],
  shop: ["Shop", "Mağaza", "محل"],
  mall: ["Mall", "Alışveriş Merkezleri", "مجمع تجاري"],
  hostel: ["Hostel", "Pansiyonlar", "نزل"],
  buildings: ["Buildings", "Binalar", "البنايات"],
  residentials: ["Residentials", "konutlar", "سكني"],
  houses: ["Houses", "evler", "منازل"],
  singleStory: ["Single story", "Tek hikaye", "قصة واحدة"],
  completeHouse: ["Complete house", "Komple ev", "منزل كامل"],
  upperPortion: ["Upper portion", "Üst kısım", "الجزء العلوي"],
  appartments: ["Appartments", "Daireler", "شقق سكنية"],
  studioAppartments: ["Studio appartments", "Stüdyo Daireler", "شقق استوديو"],
  // more_filters
  keywordSearch: [
    "Keyword Search",
    "Anahtar kelime araması",
    "بحث بكلمة مفتاحية",
  ],
  parkingSpace: ["Parking Space", "Park alanları", "أماكن لوقوف السيارات"],
  airCondition: ["Air Condition", "Hava Durumu", "تكييف هواء"],
  newlyConstructed: ["Newly Constructed", "Yeni İnşa Edildi", "شيد حديثا"],
  verifiedByZeerac: [
    "Verified by Zeerac",
    "Zeerac tarafından doğrulandı",
    "تم التحقق من قبل Zeerac",
  ],
  save: ["Save", "kaydetmek", "حفظ"],
  reset: ["Reset", "Sıfırla", "إعادة ضبط"],
  saveChanges: ["Save changes", "Değişiklikleri Kaydet", "احفظ التغييرات"],

  // agents
  searchAgent: ["Search Agent", "Arama Aracısı", "عامل البحث"],
  addAgent: ["Add Agent", "Temsilci Ekle", "إضافة وكيل"],
  agentName: ["Agent Name", "Ajan adı", "اسم العميل"],
  name: ["Name", "isim", "اسم"],
  email: ["Email", "E-posta", "البريد الإلكتروني"],
  listingsBy: ["Listings By", "Listeler", "القوائم بواسطة"],
  notProvided: ["Not Provided", "Sağlanmadı", "غير مزود"],
  typeYourMessage: ["Type your message", "Mesajını yaz", "اكتب رسالتك"],
  agentsProfile: ["Agents Profile", "Temsilci Profili", "الملف الشخصي للوكلاء"],
  noResultsFound: [
    "No Results Found",
    "Sonuç bulunamadı",
    "لم يتم العثور على نتائج",
  ],
  contactAgent: [
    "Contact This Agent",
    "Bu Temsilciyle İletişime Geçin",
    "اتصل بهذا الوكيل",
  ],
  personalInformation: [
    "Personal Information",
    "Kişisel bilgi",
    "معلومات شخصية",
  ],
  activeListings: ["Active Listings", "Aktif Listeler", "إعلانات نشطة"],
  accountId: ["Account ID", "hesap kimliği", "معرف الحساب"],
  memberSince: ["Member Since", "Den beri üye", "عضو منذ"],
  areas: ["Areas", "Alanlar", "المناطق"],
  myListings: ["My Listings", "İlanlarım", "القوائم الخاصة بي"],
  noListingsYet: ["No Listings Yet", "Henüz İlan Yok", "لا قوائم حتى الآن"],
  changePassword: ["Change Password", "Şifre değiştir", "غير كلمة السر"],
  oldPassword: ["Old Password", "Eski Şifre", "كلمة المرور القديمة"],
  newPassword: ["New Password", "Yeni Şifre", "كلمة السر الجديدة"],
  reEnterNewPassword: [
    "Re-Enter New Password",
    "Yeni şifreyi tekrar giriniz",
    "أعد إدخال كلمة المرور الجديدة",
  ],
  confirm: ["Confirm", "Onaylamak", "يتأكد"],
  // agency
  searchAgency: ["Search Agency", "Arama Ajansı", "وكالة البحث"],
  agencyName: ["Agency Name", "Ajans İsmi", "اسم الوكالة"],
  sold: ["Sold", "Satıldı", "مُباع"],
  contactCompany: [
    "Contact This Company",
    "Bu Şirketle İletişime Geçin",
    "اتصل بهذه الشركة",
  ],
  callCompany: ["Call Company", "Firmayı Arayın", "اتصل بالشركة"],
  emailCompany: ["Email Company", "E-posta Şirketi", "شركة البريد الإلكتروني"],
  comingSoon: ["Coming Soon", "Çok yakında", "قريبا"],
  ourListings: ["Our Listings", "Listelerimiz", "القوائم لدينا"],
  properties: ["Properties", "Özellikleri", "الخصائص"],

  // user profile
  myProfile: ["My Profile", "Benim profilim", "ملفي"],
  contact: ["Contact", "İletişim", "اتصال"],

  // edit profile
  username: ["User Name", "Kullanıcı adı", "اسم االمستخدم"],
  firstName: ["First Name", "İlk adı", "الاسم الاول"],

  // edit company
  companyName: ["Company Name", "Firma Adı", "اسم الشركة"],
  phone: ["Phone", "Telefon", "هاتف"],
  companyQoute: ["Company Qoute", "Şirket Teklifi", "اقتباس الشركة"],
  companyFax: ["Company Fax", "Şirket Faksı", "فاكس الشركة"],
  companyDescription: ["Company Description", "Şirket tanımı", "وصف الشركة"],

  // submit listing
  basicInformation: ["Basic Information", "Temel Bilgiler", "معلومات اساسية"],
  details: ["Details", "Detaylar", "تفاصيل"],
  gallery: ["Gallery", "Galeri", "صالة عرض"],
  viewAd: ["View Ad", "Reklamı Görüntüle", "مشاهدة الاعلان"],
  propertyName: ["Property Name", "Mülkiyet adı", "اسم الخاصية"],
  projectPurpose: ["Property Purpose", "Mülkiyet Amacı", "الغرض من الملكية"],
  propertyPrice: ["Property Price", "Emlak fiyatı", "أسعار العقارات"],
  propertySpace: ["Property Space", "Özellik Alanı", "مساحة الملكية"],
  currency: ["Currency", "Para birimi", "عملة"],
  spaceUnit: ["Space Unit", "Uzay Birimi", "وحدة الفضاء"],
  videoURL: ["Video URL", "Video linki", "رابط الفيديو"],
  saveAndProceed: ["Save & Proceed", "Kaydet ve Devam Et", "حفظ والمتابعة"],
  yearBuilt: ["Year Built", "Kuruluş yılı", "بنيت عام"],
  goBack: ["Go Back", "Geri dön", "عُد"],
  enterLocation: ["Enter a Location", "Konum Girin", "أدخل الموقع"],
  address: ["Address", "Adres", "تبوك"],
  latitude: ["Latitude", "Enlem", "خط العرض"],
  longitude: ["Longitude", "Boylam", "خط الطول"],
  block: ["Block", "Engellemek", "حاجز"],
  furnished: ["Furnished", "mobilyalı", "مفروشة"],
  parking: ["Parking", "Otopark", "موقف سيارات"],
  electricity: ["Electricity", "Elektrik", "كهرباء"],
  suigas: ["Suigas", "Su Gazı", "سوي غاز"],
  landline: ["Landline", "sabit hat", "هاتف ارضي"],
  sewerage: ["Sewerage", "Kanalizasyon", "الصرف الصحي"],
  internet: ["Internet", "internet", "إنترنت"],
  propertyThumbnail: [
    "Property Thumbnail",
    "Özellik Küçük Resmi",
    "صورة مصغرة للملكية",
  ],
  propertyGallery: ["Property Gallery", "Emlak Galerisi", "معرض الممتلكات"],
  addImages: ["Add Images", "Resim ekle", "إضافة الصور"],
  termsAndConditions: [
    "I Agree to the terms & Conditions",
    "Şartlar ve Koşulları Kabul Ediyorum",
    "أوافق على الأحكام والشروط",
  ],
  submit: ["Submit", "Göndermek", "يُقدِّم"],
  upTo: ["up to", "kadar", "يصل إلى"],

  // login
  loginToZeerac: [
    "Login to Zeerac",
    "Zeerac a giriş yapın",
    "Zeerac تسجيل الدخول إلى ",
  ],
  password: ["Password", "Şifre", "كلمة المرور"],
  forgotPassword: [
    "Forgot Password?",
    "Parolanızı mı unuttunuz?",
    "هل نسيت كلمة السر؟",
  ],
  donthaveAccount: ["Don't have an account", "hesabın yok", " ليس لديك حساب ؟"],
  signUpHere: ["SignUp here", "Buradan kaydolun", "سجل هنا "],
  or: ["OR", "VEYA", "أو"],
  anywhere: ["Anywhere", "Herhangi bir yer", "في أى مكان"],
  sell: ["Sell", "Satmak", "باع"],
  loginWithGoogle: [
    "Login with Google",
    "Google ile giriş yapın",
    "تسجيل الدخول عبر جوجل",
  ],
  loginWithFacebook: [
    "Login with Facebook",
    "Facebook ile giriş",
    "تسجيل الدخول باستخدام الفيسبوك",
  ],

  //register
  signUptoZeerac: ["Signup to Zeerac", "Zeerac a kaydolun", "Zeerac اشترك في"],
  lastName: ["Last Name", "Soy isim", "الكنية"],
  cnic: ["CNIC", "CNIC", "CNIC"],
  confirmPassword: ["Confirm Password", "Şifreyi Onayla", "تأكيد كلمة المرور"],
  signupAsAgeny: ["Signup as Agency", "Ajans olarak kaydolun", "اشترك كوكالة"],
  saveAndRegister: ["Save & Register", "Kaydet ve Kaydol", "حفظ وتسجيل"],
  addressInformation: [
    "Address Information",
    "adres bilgisi",
    "معلومات العنوان",
  ],
  companyDetail: ["Company Detail", "Şirket Detayı", "تفاصيل الشركة"],
  companyEmail: [
    "Company Email",
    "Şirket E-postası",
    "البريد الإلكتروني للشركة",
  ],

  // trends
  EnterDataHereToFindOutTrends: [
    "Enter Data here to find out trends",
    "Trendleri öğrenmek için Verileri buraya girin",
    "أدخل البيانات هنا لمعرفة الاتجاهات",
  ],

  // looking for
  NoPreferredListingsToShow: [
    "No Preferred listings to show",
    "Gösterilecek Tercih edilen liste yok",
    "لا توجد قوائم مفضلة للعرض",
  ],
  lookingFor: ["Looking For", "Arıyor", "البحث عن"],

  //user preference
  userPreferences: [
    "User Preferences",
    "Kullanıcı tercihleri",
    "خيارات المستخدم",
  ],
  propertyPurpose: ["Property Purpose", "Mülkiyet Amacı", "الغرض من الملكية"],
  propertyUnit: ["Property Unit", "Mülk Birimi", "وحدة الملكية"],
  lotSize: ["Lot Size", "Lot büyüklüğü", "حجم كبير"],

  // my rewards
  totalBalance: ["Total Rewards", "Ödüller", "المكافآت"],

  // error messages
  usernameRequired: [
    "Username Required",
    "Kullanıcı Adı Gerekli",
    "اسم المستخدم مطلوب",
  ],
  firstnameRequired: ["First Name Required", "Ad Gerekli", "الاسم الأول مطلوب"],
  lastnameRequired: ["Last Name Required", "Soyad gerekli", "نشاط اسم مطلوب"],
  invalidEmail: ["Invalid Email", "Geçersiz e-posta", "بريد إلكتروني خاطئ"],
  emailRequired: [
    "Email Required",
    "E-posta Gerekli",
    "البريد الإلكتروني مطلوب",
  ],
  passwordRequired: [
    "Password Required",
    "Şifre gerekli",
    "كلمة المرور مطلوبة",
  ],
  passwordMustMatch: [
    "Password Must Match",
    "Şifre uyuşmalı",
    "كلمة المرور يجب ان تتطابق",
  ],
  phoneNumberNotvalid: [
    "Phone Number is not valid",
    "Telefon Numarası geçerli değil",
    "رقم الهاتف غير صالح",
  ],
  cnicNotValid: ["CNIC is not valid", "CNIC geçerli değil", "CNIC غير صالح"],
  companyNameRequired: [
    "Company Name Required",
    "Şirket Adı Gerekli",
    "اسم الشركة مطلوب",
  ],
  descriptionRequired: [
    "Description Required",
    "Açıklama Gerekli",
    "الوصف مطلوب",
  ],
  cityRequired: ["City Required", "Şehir Gerekli", "City مطلوبة"],
  areaRequired: ["Area Required", "Gerekli Alan", "المنطقة مطلوبة"],
  addressRequired: ["Address required", "Adres Gerekli", "العنوان مطلوب"],
  oldPasswordMustMatch: [
    "Old Password must match",
    "Eski Şifre eşleşmelidir",
    "يجب أن تتطابق كلمة المرور القديمة",
  ],
};

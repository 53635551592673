import { configureStore } from "@reduxjs/toolkit";
import propertySlice from "./property/propertySlice";
import countrySlice from "../countrySlice";
import languageSlice from "../languageSlice";
import agentSlice from "../agentSlice";
import agencySlice from "../agencySlice";
import authSlice from "../authSlice";
import formSlice from "../formSlice";

export const store = configureStore({
  reducer: {
    properties: propertySlice,
    auth: authSlice,
    country: countrySlice,
    language: languageSlice,
    agent: agentSlice,
    agency: agencySlice,
    stepper: formSlice,
  },
});

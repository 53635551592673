import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import ContactAgent from './ContactAgent';
import PersonalInfo from './PersonalInfo';
import AgentListings from './AgentListings';
import { Divider, Grid } from '@mui/material';
import AgentBreadcrum from './AgentBreadcrum';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const useStyles = makeStyles(() => ({
  main: {
    height: '80vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: '100%',
  },
  chat: {
    padding: 5,
    backgroundColor: '#014493',
    position: 'absolute',
    right: 0,
    top: '50%',
    cursor: 'pointer',
  },
}));

const AgentDetail = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language);
  const mainUser = JSON.parse(sessionStorage.getItem('authentication') || '{}');

  return (
    <div className={classes.main}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={10} sm={10} md={8} lg={8}>
          <AgentBreadcrum />
          <Divider sx={{ background: '#014493' }} />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={'center'}
        spacing={2}
        display="flex"
        flexDirection={lang.langIndex === 2 ? 'row-reverse' : 'row'}
      >
        <Grid item xs={10} sm={10} md={6} lg={5} xl={5}>
          <PersonalInfo />
        </Grid>
        <Grid item xs={10} sm={10} md={3} lg={3}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>
              <ContactAgent />
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={10} sm={10} md={8} lg={8}>
          <AgentListings />
        </Grid>
      </Grid>
    </div>
  );
};

export default AgentDetail;

import React from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Card, Grid } from '@mui/material';
import { baseUrl } from '../../constants/baseUrls';
import { useHistory } from 'react-router-dom';
import TextTranslation from '../../constants/TextTranslation';

const useStyles = makeStyles(() => ({
  card: {
    border: '1px solid #014493',
    marginTop: 10,
    padding: 10,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    color: '#014493',
    fontFamily: 'Poopins-Regular',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 10,
  },
  btn: {
    border: 'none',
    borderRadius: 20,
    backgroundColor: '#014493',
    color: '#fff',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    width: '100%',
    cursor: 'pointer',
  },
  textarea: {
    width: '100%',
    minHeight: 100,
    border: '1px solid #014493',
    borderRadius: 20,
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 12,
    paddingLeft: 10,
    display: 'flex',
    margin: '5px 0 10px 0',
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    '&::placeholder': {
      color: '#767676',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 12,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
  },

  text: {
    color: '#014493',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 14,
    textAlign: 'center',
  },
  send: {
    border: 'none',
    borderRadius: 20,
    backgroundColor: '#014493',
    color: '#fff',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    width: 70,
    height: 30,
    cursor: 'pointer',
    textAlign: 'center',
    marginBottom: 10,
    zIndex: 100,
  },
  cancel: {
    border: 'none',
    borderRadius: 20,
    backgroundColor: '#ebebeb',
    color: '#014493',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    width: 70,
    height: 30,
    cursor: 'pointer',
    textAlign: 'center',
    marginBottom: 10,
    zIndex: 100,
  },
}));

const AgencyContact = () => {
  const classes = useStyles();
  let history = useHistory();
  const [isEmail, setIsEmail] = React.useState(false);
  const [isCall, setIsCall] = React.useState(false);
  const { dataDetail } = useSelector((state) => state.agency);
  const lang = useSelector((state) => state.language);
  const authentication = sessionStorage.getItem('authentication');
  const auth = JSON.parse(authentication);

  const customTextAreaStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    paddingRight: 10,
  };

  return (
    <Card className={classes.card} sx={{ borderRadius: 5 }}>
      <div className={classes.heading}>
        {TextTranslation.contactCompany[lang.langIndex]}
      </div>
      <Grid container justifyContent={'center'}>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <a href={`tel:${dataDetail.result?.phone}`}>
            <button className={classes.btn} onClick={() => setIsCall(!isCall)}>
              {isCall && dataDetail.result?.phone
                ? dataDetail.result?.phone
                : TextTranslation.callCompany[lang.langIndex]}
            </button>
          </a>
          {!isEmail && (
            <button
              className={classes.btn}
              onClick={() => {
                if (auth?.token) {
                  setIsEmail(true);
                } else {
                  toast.warning('Please login to send an email', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  history.push('/signin');
                }
              }}
            >
              {TextTranslation.emailCompany[lang.langIndex]}
            </button>
          )}
        </Grid>
        {isEmail && (
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Formik
              initialValues={{
                message: '',
                to: dataDetail.result?.email,
                email: auth?.email,
                phone: '',
              }}
              onSubmit={(values) => {
                if (dataDetail?.result) {
                  fetch(baseUrl + '/users/contact-agent/', {
                    method: 'POST',
                    body: JSON.stringify(values),
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                    .then((response) => response.json())
                    .then((response) => {
                      if (response.status === true) {
                        toast.success(response.message, {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          progressStyle: { backgroundColor: '#014493' },
                        });
                        setIsEmail(false);
                      } else {
                        toast.error(response.message, {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
            >
              {({ handleChange }) => (
                <Form>
                  <textarea
                    rows={3}
                    name="message"
                    onChange={handleChange}
                    placeholder={
                      TextTranslation.typeYourMessage[lang.langIndex]
                    }
                    className={classes.textarea}
                    style={customTextAreaStyle}
                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <button
                      className={classes.cancel}
                      onClick={() => setIsEmail(false)}
                    >
                      {TextTranslation.cancel[lang.langIndex]}
                    </button>
                    <button type="submit" className={classes.send}>
                      {TextTranslation.send[lang.langIndex]}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default AgencyContact;

import React from 'react';
import Routes from './routes/Routes';
import { store } from './features/store';
import { Provider } from 'react-redux';
import AuthProvider from './context/auth';

const App = () => {
  return (
    <>
      <AuthProvider>
        <Provider store={store}>
          <Routes />
        </Provider>
      </AuthProvider>
    </>
  );
};

export default App;

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Layout from '../../customComponents/layout/Layout';
import ListingTable from "../../customComponents/listingTable"

const Listing = () => {
  return (
    <Layout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
          <ListingTable />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Listing;

import React, { useState, useContext, useEffect } from "react";
import Layout from "../../customComponents/layout/Layout";
import { Formik, Form } from "formik";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { makeStyles } from "@mui/styles";
import Colors from "../../config/Colors";
import { useHistory } from "react-router";
import axios from "axios";
import {
  Container,
  FormControl,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import CustomField from "../../customComponents/customField/CustomField";
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "3% 10% 0% 10%",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
    marginTop: 5,
  },
  addNew: {
    color: Colors.grey,
    fontSize: 12,
    fontStyle: "italic",
    cursor: "pointer",
  },
}));

const AddNewCourse = () => {
  const classes = useStyles();
  let history = useHistory();
  const [gettoken, settoken] = useState("");
  const [logo, setLogo] = useState();

  const [addList, setAddList] = useState([
    {
      question: "",
      correct_answer: "",
      option_1: "",
      option_2: "",
      option_3: "",
      option_4: "",
    },
  ]);
  const handleInputChange = (e, index, check) => {
    if (check) {
      const { name, value } = e.target;
      const list = [...addList];
      list[index][name] = value;
      setAddList(list);
    }
  };
  useEffect(() => {
    settoken(localStorage.getItem("token"));
  }, []);

  const handleAddClick = () => {
    setAddList([
      ...addList,
      {
        question: "",
        correct_answer: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
      },
    ]);
  }; // handle click event of the Remove

  const handleRemoveClick = (index) => {
    const list = [...addList];
    list.splice(index, 1);
    setAddList(list);
  };
  const initialValues = {
    course_title: "",
    course_description: "",
    // course_video: "",
  };

  const submitCourse = (values) => {

    const allinputdata = { ...values, "questions" :[...addList] };
    console.log("cccccccccccc",allinputdata)
  //   let formData =new FormData();
  //   formData.append("course_title",allinputdata?.course_title);
  //   formData.append("course_description",allinputdata?.course_description);
  //   formData.append("course_video",logo);
  //   // formData.append("questions",allinputdata?.questions);
  //   let questionsData =new FormData();
  //   allinputdata?.questions?.forEach((elem,index)=>{
  //     console.log({elem});
  //     questionsData.append(`option${index+1}`,elem.option_1);
  //     questionsData.append(`option${index+2}`,elem.option_2);
  //     questionsData.append(`option${index+3}`,elem.option_3);
  //     questionsData.append(`option${index+4}`,elem.option_4);
  //     questionsData.append(`question`,elem.question);
  //     questionsData.append(`correct_answer`,elem.correct_answer);
  // })
  //   formData.append("questions",questionsData)
  //   for (var pair of questionsData.entries()) {
  //       console.log(pair[0]+ ', ' + pair[1]); 
  //   }

  axios.post(`https://api.zeerac.com/users/video-course/`,allinputdata,{
        headers: {
            Authorization: `token ${gettoken}`,
            "Content-Type": "application/json",
          },
          
      })
      .then((res) => res.data.status ? uploadVideo(res?.data?.result?.id) : console.log("error",res));
  };

  const uploadVideo =(id)=>{
    let formData =new FormData();
    formData.append("course_video", logo);
    
    axios.put(`https://api.zeerac.com/users/video-course/${id}/`,formData,{
      headers: {
          Authorization: `token ${gettoken}`,
          "Content-Type": "application/json",
        },
        
    }).then((res) => console.log("error",res));
  }

  

  console.log("asdasdasdasd", addList);
  return (
    <Layout>
      <div>
        <Container fullWidth>
          <div>
            <Typography
              component="h1"
              variant="h5"
              className="text-center mb-2"
            >
              Add New Course
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                //    console.log("vlaues",values)
                submitCourse(values);
              }}
            >
              {({ handleChange, handleBlur }) => (
                <Typography component="div" sx={{ width: "100%" }}>
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div className="profileEditField">
                          <label>Course Title </label>

                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // placeholder={projectDetail?.property_type}
                            name="course_title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="profileEditField">
                          <label>Course Description</label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="course_description"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        <div className="profileEditField">
                          <label>Course Video</label>

                          <input
                            onBlur={handleBlur}
                            name="course_video"
                            type="file"
                            onChange={(event) => {
                              setLogo(event.target.files[0]);
                              console.log(event.target.files[0]);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: "20px",
                      }}
                    ></Typography>

                    {addList.map((item, index) => (
                      <>
                        <FormControl
                          key={index}
                          fullWidth
                          className={classes.fields}
                        >
                          <CustomField //onChange={handleChange}
                            onBlur={handleBlur}
                            name="question"
                            label="Question"
                            placeholder="Question" //value={item.title}
                            onChange={(e) => handleInputChange(e, index, true)}
                          />
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }} //onClick={handleRemove}
                            onClick={() => handleRemoveClick(index)}
                          />
                        </FormControl>

                        <FormControl fullWidth className={classes.fields}>
                          <CustomField //onChange={handleChange}
                            onBlur={handleBlur}
                            name="correct_answer"
                            label="Answer"
                            placeholder="Answer"
                            onChange={(e) => handleInputChange(e, index, true)}
                          />
                        </FormControl>

                        <Grid container spacing={3} sx={{ marginTop: "0px" }}>
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.fields}>
                              <CustomField //onChange={handleChange}
                                onBlur={handleBlur}
                                name="option_1"
                                label="Option one"
                                placeholder="Option one"
                                onChange={(e) =>
                                  handleInputChange(e, index, true)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.fields}>
                              <CustomField //onChange={handleChange}
                                onBlur={handleBlur}
                                name="option_2"
                                label="Option two"
                                placeholder="Option two"
                                onChange={(e) =>
                                  handleInputChange(e, index, true)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.fields}>
                              <CustomField //onChange={handleChange}
                                onBlur={handleBlur}
                                name="option_3"
                                label="Option three"
                                placeholder="Option three"
                                onChange={(e) =>
                                  handleInputChange(e, index, true)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.fields}>
                              <CustomField //onChange={handleChange}
                                onBlur={handleBlur}
                                name="option_4"
                                label="Option Four"
                                placeholder="Option Four"
                                onChange={(e) =>
                                  handleInputChange(e, index, true)
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    <Typography
                      component="div"
                      className={classes.addNew} //onClick={handleAddMore}
                      onClick={handleAddClick}
                    >
                      Add More ?
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <Button
                        variant="outlined"
                        type="submit" // onClick={submitProject} //onClick={() => history.push('/viewnewproject')}
                      >
                        Submit
                      </Button>
                    </div>
                    <br />
                    {/* <div onClick={() => history.push("/viewnewproject")}>
                    next                    
                  </div> */}
                  </Form>
                </Typography>
              )}
            </Formik>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default AddNewCourse;

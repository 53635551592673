import React from "react";
import { styled } from "@mui/system";
import AddressInfo from "./AddressInfo";
import { useSelector } from "react-redux";
import PersonalInfo from "./PersonalInfo";
import CompanyDetail from "./CompanyDetail";
import TextTranslation from "../../constants/TextTranslation";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import {
  TabsUnstyled,
  TabsListUnstyled,
  TabPanelUnstyled,
  buttonUnstyledClasses,
} from "@mui/base";

const Tab = styled(TabUnstyled)`
  color: #7d7d7d;
  cursor: pointer;
  font-size: 12px;
  font-family: Poopins-SemiBold;
  background-color: #d6d6d6;
  width: 200px;
  padding: 0px 16px;
  margin: 0px 1px;
  border: none;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #014493;
    color: #fff;
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #014493;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #014493;
    color: #fff;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Poopins-SemiBold;
  font-size: 12px;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const FormTabs = () => {
  const [nextTab, setNextTab] = React.useState(0);
  const [agent, setAgent] = React.useState(false);
  const lang = useSelector((state) => state.language);

  const data = [
    TextTranslation.personalInformation[lang.langIndex],
    TextTranslation.addressInformation[lang.langIndex],
    TextTranslation.companyDetail[lang.langIndex],
  ];

  return (
    <TabsUnstyled defaultValue={0} value={nextTab}>
      <TabsList
        sx={{
          display: "flex",
          flexDirection: lang.langIndex === 2 ? "row-reverse" : "row",
        }}
      >
        {data.map((item, index) => (
          <div key={index}>
            {!agent && item === "Company Detail" ? <></> : <Tab>{item}</Tab>}
          </div>
        ))}
      </TabsList>
      <div
        style={{ border: "2px solid #014493", borderRadius: 20, padding: 10 }}
      >
        <TabPanel value={0}>
          <PersonalInfo setNextTab={setNextTab} />
        </TabPanel>
        <TabPanel value={1}>
          <AddressInfo
            setNextTab={setNextTab}
            agent={agent}
            setAgent={setAgent}
          />
        </TabPanel>
        {agent && (
          <TabPanel value={2}>
            <CompanyDetail setNextTab={setNextTab} />
          </TabPanel>
        )}
      </div>
    </TabsUnstyled>
  );
};

export default FormTabs;

import TextTranslation from "./TextTranslation";

export const validateInputField = (input) => {
  if (input === "") return false;
  // eslint-disable-next-line
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (format.test(input)) return false;

  return true;
};
export const validateEmail = (input) => {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return input.match(emailRegex);
};
export const validatePassword = (input) => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

  if (!input.match(passwordRegex)) return false;

  return true;
};
export const populatePurposeData = (lang, label) => {
  return [
    label,
    TextTranslation.forRent[lang.langIndex],
    TextTranslation.forSale[lang.langIndex],
    TextTranslation.forInstalment[lang.langIndex],
  ];
};
export const getCountryNameFromInitials = (initials) => {
  switch (initials) {
    case "pk":
      return "Pakistan";
    case "tr":
      return "Turkey";
    case "ae":
      return "UAE";
    default:
      return initials;
  }
};

//FOR DEV LOGS ONLY
export const customLog = (logValue) => {
  console.log(logValue);
};

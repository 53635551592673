import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

import {
  Container,
  FormControl,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import CustomField from "../../customComponents/customField/CustomField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Colors from "../../config/Colors";
import { baseUrl } from "./../constants/baseUrls";
import Layout from "../../customComponents/layout/Layout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncPropjectDetail,
  getProjectDetail,
} from "../../features/store/property/propertySlice";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "3% 10% 0% 10%",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
    marginTop: 5,
  },
  addNew: {
    color: Colors.grey,
    fontSize: 12,
    fontStyle: "italic",
    cursor: "pointer",
  },
}));

const EditProject = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const projectDetail = useSelector(getProjectDetail);
  console.log({ projectDetail });

  const reduxData = async () => {
    await dispatch(fetchAsyncPropjectDetail(id));
  };

  useEffect(() => {
    reduxData();
  }, []);

  const notify = () =>
    toast("🦄 Project Successfully added!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  let history = useHistory();
  const classes = useStyles();
  const [addList, setAddList] = useState([
    { title: "", body: "", images: [], contains_files: false },
  ]);
  const [addFiles, setAddFiles] = useState([]);
  const [logo, setLogo] = useState();

  const handleInputChange = (e, index, check) => {
    if (check) {
      const { name, value } = e.target;
      const list = [...addList];
      list[index][name] = value;
      setAddList(list);
    } else {
      const { name, files } = e.target;
      let filesObj = { title: addList[index].title, file: files };
      let prevObj = addFiles;
      prevObj.push(filesObj);
      console.log(prevObj);
      setAddFiles(prevObj);
    }
  }; // handle click event of the Add

  const handleAddClick = () => {
    setAddList([...addList, { title: "", body: "" }]);
  }; // handle click event of the Remove

  const handleRemoveClick = (index) => {
    const list = [...addList];
    list.splice(index, 1);
    setAddList(list);
  };

  const [response, setResponse] = useState({});
  const [unique, setUnique] = useState(false);

  const submitProject = (data) => {
    data.content = addList; // let fData = new FormData() // fData.append('projectid', data.projectid) // fData.append('title', data.title) // fData.append('locality', data.locality) // fData.append('property_type', data.property_type) // fData.append('city', data.city) // fData.append('country', data.country) // fData.append('min_price', data.min_price) // fData.append('max_price', data.max_price) // fData.append('createddate', data.createddate) // fData.append('updateddate', data.updateddate) // fData.append('logo', data.logo) // fData.append('property_url', data.property_url)

    fetch(`${baseUrl}/` + `users/project/${id}` + "/", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          notify();
          setResponse(response.result);
          response.result.content.map((item, index) => {
            uploadFiles(index, item.id);
          });
        }

        let formData = new FormData();
        formData.append("logo", logo);

        fetch(`${baseUrl}/` + "users/project/" + response.result.id + "/", {
          method: "PUT",
          body: formData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let x = projectDetail?.id;
  useEffect(() => {}, [x]);
  let initialValues = {
    projectid: projectDetail?.id,
    title: projectDetail?.title,
    locality: projectDetail?.locality,
    property_type: projectDetail?.property_type,
    city: projectDetail?.city,
    min_price: projectDetail?.min_price,
    max_price: projectDetail?.max_price,
    logo: "",
    property_url: projectDetail?.property_url,
    country: projectDetail?.country,
    content: [
      {
        id: " ",
        // files: [],
        title: "title 1 ",
        body: "desc 1 ",
        contains_files: true,
      },
    ],
  };

  const uploadFiles = (index, id) => {
    console.log(addFiles[index], "files");
    let formData = new FormData();
    formData.append("len", addFiles[index].file.length);
    formData.append("id", id);
    formData.append("logo", logo);
    Array.from(addFiles[index].file).forEach((file, i) => {
      formData.append("title", addFiles[index].title);
      formData.append("file[" + i + "]", file);
      formData.append("file_name", file);
    });
    fetch(`${baseUrl}/` + "users/upload-files/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("upload files response", response);
        if (response.status === true) {
          console.log(response, "rs");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <>
        <Container fullWidth>
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              className="text-center mb-2"
            >
              Edit Project
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {
                let x = {
                  projectid: values.projectid,
                  title: values.title,
                  locality: values.locality,
                  property_type: values.property_type,
                  city: values.city,
                  content: initialValues.content,
                  country: values.country,
                  createddate: values.createddate,
                  updateddate: values.updateddate,
                  property_url: values.property_url,
                  min_price: values.min_price,
                  max_price: values.max_price,
                };
                // console.log("vlaues",x)
                submitProject(x);
              }}
            >
              {({ handleChange, handleBlur }) => (
                <Typography component="div" sx={{ width: "100%" }}>
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                      <div className="profileEditField">
                      <label>ID</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="asdasdasd"
                        name="projectid"
                        readOnly
                        disabled
                        defaultValue={initialValues.projectid}
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                      <div className="profileEditField">
                      <label>TTitle</label>

                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="title"
                        defaultValue={projectDetail?.title}
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                      <div className="profileEditField">
                      <label>Property Type</label>

                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // placeholder={projectDetail?.property_type}
                        name="property_type"
                        defaultValue={projectDetail?.property_type}
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                      <div className="profileEditField">
                      <label>Locality</label>

                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="locality"
                        defaultValue={projectDetail?.locality}
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                      <div className="profileEditField">
                      <label>City</label>

                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="city"
                        defaultValue={projectDetail?.city}
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                        
                    <div className="profileEditField">
                      <label>Country</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="country"
                        label="Country"
                        defaultValue={projectDetail?.country}
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                      <div className="profileEditField">
                      <label>Minimum Price</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={projectDetail?.min_price}
                        type="number"
                        name="min_price"
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                        
                    <div className="profileEditField">
                      <label>Maximum Price</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={projectDetail?.max_price}
                        type="number"
                        name="max_price"
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}>
                        
                    <div className="profileEditField">
                      <label>Created Date</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={projectDetail?.createddate}
                        type="date"
                        name="createddate"
                      />
                    </div>
                      </Grid>
                      <Grid item xs={6}> 
                    <div className="profileEditField">
                      <label>Updated Date</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={projectDetail?.updateddate}
                        type="date"
                        name="updateddate"
                      />
                    </div></Grid>
                      <Grid item xs={6}>   <div className="profileEditField">
                      <label>Property URL</label>

                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={projectDetail?.property_url}
                        name="property_url"
                      />
                    </div></Grid>
                    <Grid item>
                    <div
                     className="profileEditField"
                    >
                      <label>Profile Logo</label>
                      <input
                        id="logo"
                        name="logo"
                        type="file"
                        accept="image/x-png,image/jpeg"
                        onChange={(event) => {
                          setLogo(event.target.files[0]);
                          console.log(event.target.files[0]);
                        }}
                      />
                    </div>
                    </Grid>
                    </Grid>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></Typography>
                    {projectDetail &&
                      projectDetail?.content?.map((item, index) => (
                        <div style={{ margin: "10px 0" }}>
                          <FormControl
                            key={index}
                            fullWidth
                            className={classes.fields}
                          >
                            <CustomField //onChange={handleChange}
                              onBlur={handleBlur}
                              name="title"
                              label="Title"
                              placeholder={item?.title}
                              onChange={(e) =>
                                handleInputChange(e, index, true)
                              }
                            />
                            <DeleteOutlineIcon
                              fontSize="small"
                              sx={{ cursor: "pointer" }} //onClick={handleRemove}
                              onClick={() => handleRemoveClick(index)}
                            />
                          </FormControl>
                          {item &&
                            item?.files.map((i) => {
                              return (
                                <img
                                  src={`https://api.zeerac.com/` + i?.file}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: "10px 10px 10px 0px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                                  }}
                                />
                              );
                            })}
                          <FormControl fullWidth className={classes.fields}>
                            <CustomField //onChange={handleChange}
                              onBlur={handleBlur}
                              name="body"
                              label="Description"
                              placeholder={item?.body}
                              multiline
                              minRows={4} //value={item.description}
                              onChange={(e) =>
                                handleInputChange(e, index, true)
                              }
                            />
                          </FormControl>
                          <FormControl fullWidth className={classes.fields}>
                            <input
                              id="image"
                              className="custom-file-input"
                              type="file"
                              accept="image/x-png,image/jpeg"
                              multiple="multiple" //onChange={handleChange}
                              name="image"
                              label="Image"
                              placeholder="Image" //value={item.image} // onChange={(e) => { //   console.log(e.target.files) // }}
                              onChange={(e) =>
                                handleInputChange(e, index, false)
                              } //onChange={onChange}
                            />
                          </FormControl>
                        </div>
                      ))}
                    {addList.map((item, index) => (
                      <>
                        <FormControl
                          key={index}
                          fullWidth
                          className={classes.fields}
                        >
                          <CustomField //onChange={handleChange}
                            onBlur={handleBlur}
                            name="title"
                            label="Title"
                            placeholder="Title" //value={item.title}
                            onChange={(e) => handleInputChange(e, index, true)}
                          />
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }} //onClick={handleRemove}
                            onClick={() => handleRemoveClick(index)}
                          />
                        </FormControl>
                        <FormControl fullWidth className={classes.fields}>
                          <CustomField //onChange={handleChange}
                            onBlur={handleBlur}
                            name="body"
                            label="Description"
                            placeholder="Description"
                            multiline
                            minRows={4} //value={item.description}
                            onChange={(e) => handleInputChange(e, index, true)}
                          />
                        </FormControl>
                        <FormControl fullWidth className={classes.fields}>
                          <input
                            id="image"
                            className="custom-file-input"
                            type="file"
                            accept="image/x-png,image/jpeg"
                            multiple="multiple" //onChange={handleChange}
                            name="image"
                            label="Image"
                            placeholder="Image" //value={item.image} // onChange={(e) => { //   console.log(e.target.files) // }}
                            onChange={(e) => handleInputChange(e, index, false)} //onChange={onChange}
                          />
                        </FormControl>
                      </>
                    ))}
                    <Typography
                      component="div"
                      className={classes.addNew} //onClick={handleAddMore}
                      onClick={handleAddClick}
                    >
                      Add More ?
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <Button
                        variant="outlined"
                        type="submit" // onClick={submitProject} //onClick={() => history.push('/viewnewproject')}
                      >
                        Add Project
                      </Button>
                    </div>
                    <br />
                    {/* <div onClick={() => history.push("/viewnewproject")}>
                    next                    
                  </div> */}
                  </Form>
                </Typography>
              )}
            </Formik>
          </div>
        </Container>
        <ToastContainer />
      </>
    </Layout>
  );
};

export default EditProject;

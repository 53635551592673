import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router";
import { Formik, Form } from "formik";
import {
  Container,
  FormControl,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import CustomField from "../../customComponents/customField/CustomField";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Colors from "../../config/Colors";
import HouseIcon from "@mui/icons-material/House";
import WaterIcon from "@mui/icons-material/Water";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { useSelector, useDispatch } from "react-redux";
import {
  getPropertyDetailForUpdate,
  postAsyncUpdatePropertyDetail,
} from "../../features/store/property/propertySlice";
import electricity from "../../assets/icons/electricity.png";
import furnished from "../../assets/icons/furnished.png";
import parking from "../../assets/icons/parking.png";
import phone from "../../assets/icons/phone.png";
import sewerage from "../../assets/icons/sewerage.png";
import suigas from "../../assets/icons/suigas.png";
import wifi from "../../assets/icons/wifi.png";
import { Col, Row, Card } from "react-bootstrap";
// styles

const features = [
  { id: 1, icon: furnished, title: "Furnished", name: "furnished" },
  { id: 2, icon: parking, title: "Parking", name: "parking" },
  { id: 3, icon: electricity, title: "Electricity", name: "electricity" },
  { id: 4, icon: suigas, title: "Sui Gas", name: "suigas" },
  { id: 5, icon: phone, title: "Landline", name: "landline" },
  { id: 6, icon: sewerage, title: "Sewerage", name: "sewerage" },
  { id: 7, icon: wifi, title: "Internet Connection", name: "internet" },
];
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "5% 10% 0% 10%",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  iconDiv: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: 10,
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 100,
  },
  iconText: {
    fontSize: 14,
  },
}));

const FeatureInfo = () => {
  let paramFromDetailListing = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const data = useSelector(getPropertyDetailForUpdate);
  const [propertyFeature, setPropertyFeature] = React.useState({
    furnished: false,
    parking: false,
    electricity: false,
    suigas: false,
    landline: false,
    sewerage: false,
    internet: false,
  });
  const onChangeFeature = (e) => {
    const name = e.target.name;
    setPropertyFeature({
      ...propertyFeature,
      [name]: !propertyFeature[name],
    });
    console.log("Selected Data", propertyFeature);
  };

  const getUpdateData = (values) => {
    const id = paramFromDetailListing.id;

    values = {
      ...values,
      features: { ...propertyFeature },
    };
    console.log("here is issue", values);
    const val = { values, id };
    dispatch(postAsyncUpdatePropertyDetail(val));
  };

  return (
    <Container fullWidth>
      <div className={classes.paper}>
        <Formik
          fullWidth
          initialValues={{
            beds: data.beds,
            bathrooms: data.bathrooms,
            condition: data.condition,
            year: data.year,
            neighborhood: data.neighborhood,
            features: propertyFeature,
          }}
          onSubmit={(values) => {
            getUpdateData(values);
          }}
        >
          {({ handleChange, handleBlur }) => (
            <Typography component="div" sx={{ width: "100%" }}>
              <Form>
                <Grid container spacing={3} sx={{mb:5}}>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Beds</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="beds"
                        defaultValue={data.beds}
                        type="number"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Bathroom</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="bathrooms"
                        defaultValue={data.bathrooms}
                        type="number"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Condition</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="condition"
                        defaultValue={data.condition}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Property Year Built</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="year"
                        placeholder={data.year}
                        type="number"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profileEditField">
                      <label>Neighborhood</label>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="neighborhood"
                        defaultValue={data.neighborhood}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Row style={{ width: "70%", margin: "auto" }}>
                  {features.map((item, index) => (
                    <Col lg={3} key={index}>
                      <Card
                        className={classes.card}
                        style={{
                          backgroundColor:
                            propertyFeature[item.name] === true
                              ? "#01449340"
                              : "#fff",
                        }}
                        onClick={(e) => {
                          onChangeFeature(e);
                        }}
                      >
                        <div className={classes.inner}>
                          <img src={item.icon} />
                          <span className={classes.name}>{item.name}</span>
                          <input
                            className={classes.check}
                            type="checkbox"
                            name={item.name}
                            value={item.name}
                            onChange={(e) => {
                              onChangeFeature(e);
                              //handleChange(e);
                            }}
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <div style={{ padding: 10 }} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <Button variant="outlined" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
            </Typography>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default FeatureInfo;

import React, { useEffect, useState } from "react";
import axios from "axios";
import VideoPlayer from "react-video-js-player";
import { Modal, Button } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const LearnCourse = () => {
  const auth = (localStorage.getItem("token"));
  const [modalShow, setModalShow] = useState(false);
  const [mcqData, setmcsData] = useState([]);
 
  const getVideoCourse = () => {
     axios
      .get(`https://api.zeerac.com/users/video-course/`, {
        headers: { Authorization: `token ${auth}` },
      })
      .then((res) => {
        console.log({res});
        setmcsData(res.data.result)
      })
      
  };
  

  useEffect(() => {
    getVideoCourse();
  }, []);

  const [value, setValue] = useState([]);
  const [svalue, setSvalue] = useState([]);
  const handleChange = (e, id, ans, ques) => {
    const { value } = e.target;
    if (value == ans) {
      var status = true;
    } else {
      status = false;
    }
    setValue((prevState) => ({
      ...prevState,
      [id]: { selected: value, answer: ans, status: status, question: ques },
    }));
  };
  const handleSubmitt = () => {
    setSvalue(value);
  };

  function ResultModal(props) {
    const { data } = { ...props };
    console.log("data", data);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bolder", fontSize: "20px" }}>
                    Question
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bolder", fontSize: "20px" }}
                  >
                    Your Answer
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bolder", fontSize: "20px" }}
                  >
                    Result
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bolder", fontSize: "20px" }}
                  >
                    Correct Answer
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data).map(function (key, index) {
                  return (
                    <TableRow key={index.name} sx={{ border: 2 }}>
                      <TableCell component="th" scope="row">
                        {data[key].question}
                      </TableCell>
                      <TableCell align="right">{data[key].selected}</TableCell>
                      <TableCell align="right">
                        {" "}
                        {data[key].status ? (
                          <CheckIcon color="success" />
                        ) : (
                          <ClearIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell align="right">{data[key].answer}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <table></table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <div className="learn-course">
      <h2 style={{fontWeight:900}}>Learning For Your Career !!!</h2>
      {mcqData?.map((item, index) => {
        return (
          <>
            <VideoPlayer
              src={`https://api.zeerac.com/users/" + ${item.course_video}#.mp4`}
              width="720"
              height="420"
            />

            <h3>{item.course_title}</h3>
            <h5>{item.course_description}</h5>
            {item?.questions?.map((ques, index) => {
              return (
                <>
                  <div className="mt-5" key={index}>
                    <p>
                      <b className="me-2">{ques?.id} :</b>
                      {ques?.question}
                    </p>
                    <span
                      style={{ border: "1px solid black" }}
                      className="d-flex align-items-center px-2 mb-2"
                    >
                      <input
                        type="radio"
                        id={ques?.option_1}
                        name={index}
                        value={ques?.option_1}
                        onChange={(e) => {
                          handleChange(
                            e,
                            ques?.id,
                            ques?.correct_answer,
                            ques?.question
                          );
                        }}
                      />
                       
                      <label
                        for={ques?.option_1}
                        style={{
                          fontSize: "16px",
                          fontWeight: "bolder",
                          padding: "7px 0px",
                        }}
                        className="d-flex w-100 ms-1"
                      >
                        {ques?.option_1}
                      </label>
                    </span>
                    <span
                      style={{ border: "1px solid black" }}
                      className="d-flex align-items-center px-2 mb-2"
                    >
                      <input
                        type="radio"
                        id={ques?.option_2}
                        name={index}
                        value={ques?.option_2}
                        onChange={(e) => {
                          handleChange(
                            e,
                            ques?.id,
                            ques?.correct_answer,
                            ques?.question
                          );
                        }}
                      />
                       
                      <label
                        for={ques?.option_2}
                        style={{
                          fontSize: "16px",
                          fontWeight: "bolder",
                          padding: "7px 0px",
                        }}
                        className="d-flex w-100 ms-1"
                      >
                        {ques?.option_2}
                      </label>
                    </span>{" "}
                    <span
                      style={{ border: "1px solid black" }}
                      className="d-flex align-items-center px-2 mb-2"
                    >
                      <input
                        type="radio"
                        id={ques?.option_3}
                        name={index}
                        value={ques?.option_3}
                        onChange={(e) => {
                          handleChange(
                            e,
                            ques?.id,
                            ques?.correct_answer,
                            ques?.question
                          );
                        }}
                      />
                       
                      <label
                        for={ques?.option_3}
                        style={{
                          fontSize: "16px",
                          fontWeight: "bolder",
                          padding: "7px 0px",
                        }}
                        className="d-flex w-100 ms-1"
                      >
                        {ques?.option_3}
                      </label>
                    </span>{" "}
                    <span
                      style={{ border: "1px solid black" }}
                      className="d-flex align-items-center px-2 mb-2"
                    >
                      <input
                        type="radio"
                        id={ques?.option_4}
                        name={index}
                        value={ques?.option_4}
                        onChange={(e) => {
                          handleChange(
                            e,
                            ques?.id,
                            ques?.correct_answer,
                            ques?.question
                          );
                        }}
                      />
                       
                      <label
                        for={ques?.option_4}
                        style={{
                          fontSize: "16px",
                          fontWeight: "bolder",
                          padding: "7px 0px",
                        }}
                        className="d-flex w-100 ms-1"
                      >
                        {ques?.option_4}
                      </label>
                    </span>
                  </div>
                </>
              );
            })}
            <button
              type="submit"
              className="btn  btn-success px-5 d-flex my-5 m-auto"
              onClick={() => {
                handleSubmitt();
                setModalShow(true);
              }}
            >
              Submit
            </button>
          </>
        );
      })}
      <ResultModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={svalue}
      />
    </div>
  );
};

export default LearnCourse;

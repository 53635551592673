import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../components/login/Login';
import Listing from '../components/listing/Listing';
import Chat from '../components/chat';
import UpdateListing from '../components/listing/UpdateListing';
import ListingDetail from '../components/listing/ListingDetail';
import AddNewProject from '../components/addNewProject/AddNewProject';
import ViewNewProject from '../components/addNewProject/ViewNewProject';
import PrivateRoute from './PrivateRoute';
import AllProjects from '../components/projects/AllProjects';
import ProjectDetail from '../components/projects/ProjectDetail';
import ListingChart from '../components/listingChart';
import EditProject from '../components/projects/EditProject';
import Course from '../components/course';
import AddNewCourse from '../components/course/AddNewCourse';
import Agentarchive from '../components/agents/Agentarchive';
import Agentdetails from '../components/agents/Agentdetails';
import AddAgents from '../components/agents/AddAgents';
import Agencyarchive from '../components/agency/Agencyarchive';
import Agencydetails from '../components/agency/Agencydetails';
import RegisterCompany from '../components/RegisterCompany';
const Routes = () => {
  return (
    <Switch>
      <Route path="/" component={Login} exact />
      <Route path="/listingchart" component={ListingChart} exact />

      {/* <Route path="/listing" component={Listing} /> */}
      <PrivateRoute component={ListingDetail} path="/listingDetail/:id" exact />
      <PrivateRoute component={UpdateListing} path="/updatelisting/:id" exact />
      {/* <PrivateRoute component={Main} path="/chat" exact /> */}
      <PrivateRoute component={Chat} path="/Chat" exact />
      <PrivateRoute component={AddNewProject} path="/addnewproject" exact />
      <PrivateRoute component={ViewNewProject} path="/viewnewproject" exact />
      <PrivateRoute component={AllProjects} path="/projects" exact />
      <PrivateRoute component={ProjectDetail} path="/projectDetail/:id" exact />
      <PrivateRoute component={EditProject} path="/EditProject/:id" exact />
      <PrivateRoute component={Listing} path="/listing" exact />
      <PrivateRoute component={Course} path="/course" exact />
      <PrivateRoute component={AddNewCourse} path="/add-new-course" exact />
      <PrivateRoute component={Agentarchive} path="/agents" exact />
      <PrivateRoute component={Agentdetails} path="/agent-detail/:id" exact />
      <PrivateRoute component={AddAgents} path="/add-agent" exact />
      <PrivateRoute component={Agencyarchive} path="/agencies" exact />
      <PrivateRoute component={Agencydetails} path="/agency-detail/:id" exact />
      <PrivateRoute component={RegisterCompany} path="/register-company" exact />
      <Route path="*" component={<h1>Path Not Found</h1>} />
      {/* if user loggedIn then main screen else login */}
      {/* <Route
            path="/home"
            render={() =>
              localStorage.getItem("token") ? <Home/> : <Login />
            }
          /> */}
      {/* <Route component={PageNotFound} /> */}
    </Switch>
  );
};

export default Routes;

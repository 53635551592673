import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Card } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import { baseUrl } from '../../constants/baseUrls';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { agentListings } from '../../../features/agentSlice';
import zeeracIdent from '../../../assets/misc/zeeracIdent.svg';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TextTranslation from '../../constants/TextTranslation';

const useStyles = makeStyles(() => ({
  tag: {
    backgroundColor: '#014493',
    fontSize: 12,
    fontFamily: 'Poopins-SemiBold',
    color: '#fff',
    padding: '5px 40px',
    width: 'fit-content',
    marginTop: 10,
    marginLeft: 20,
  },
  main: {
    padding: '20px 0',
    border: '1px solid #014493',
    borderRadius: 20,
  },
  found: {
    color: '#014493',
    fontFamily: 'Poopins-Bold',
    fontSize: 20,
    borderBottom: '1px solid #014493',
    width: 200,
    margin: '20px 0',
  },
  card: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 10px',
    borderRadius: 10,
    border: 'none',
    height: 220,
    overflow: 'hidden',
  },
  image: {
    border: '1px solid #014493',
    borderRadius: 10,
    height: 120,
    width: '100%',
  },
  ident: {
    position: 'absolute',
    bottom: -80,
    right: -70,
    zIndex: 1,
    width: 120,
    transform: 'rotate(15deg)',
  },
  favourite: {
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 20,
    cursor: 'pointer',
    color: '#dc143c',
  },
  price: {
    color: '#014493',
    marginLeft: 10,
    marginTop: 2,
    fontFamily: 'Poopins-SemiBold',
    fontSize: 14,
  },
  bbm: {
    color: '#014493',
    fontSize: 10,
    fontFamily: 'Poopins-SemiBold',
    marginLeft: 10,
    lineHeight: 1,
  },
  address: {
    color: '#014493',
    fontSize: 10,
    fontFamily: 'Poopins-SemiBold',
    marginLeft: 10,
    lineHeight: 2,
  },
  zeerac: {
    fontSize: 10,
    fontFamily: 'Poopins-SemiBold',
    color: '#d5d5d5',
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
  },
}));

const AgentListings = () => {
  const classes = useStyles();
  const paramFromAgentList = useParams();
  const dispatch = useDispatch();
  const { agent_Listings } = useSelector((state) => state.agent);
  const lang = useSelector((state) => state.language);

  const ReduxData = async () => {
    await dispatch(agentListings(paramFromAgentList.id));
  };

  useEffect(() => {
    ReduxData();
  }, []);

  return (
    <div>
      <div className={classes.tag}>
        {TextTranslation.myListings[lang.langIndex]}
      </div>
      <div className={classes.main}>
        {agent_Listings?.result?.count > 0 ? (
          <Grid container justifyContent="space-around" spacing={2}>
            {agent_Listings?.result?.results?.map((item, index) => (
              <Grid item xs={10} sm={10} md={4.5} lg={3.5} xl={3.5} key={index}>
                <Card className={classes.card}>
                  <Link to={`/listing-detail/${item.id}`}>
                    <img
                      src={`${baseUrl}/${item?.thumbnail}`}
                      
                      alt="img"
                      className={classes.image}
                    />
                  </Link>
                  <img src={zeeracIdent} alt="img" className={classes.ident} />

                  <FavoriteBorderIcon className={classes.favourite} />

                  <div className={classes.price}>${item.price}</div>
                  <span className={classes.bbm}>
                    {item?.beds} Beds - {item?.bathrooms} Baths - {item?.space}
                    &nbsp; {item?.unit}
                  </span>
                  <span className={classes.address}>{item?.address}</span>
                  <span className={classes.zeerac}>Listing By : Zeerac</span>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <h2 className="text-center p-5" style={{ color: '#014493' }}>
            {TextTranslation.noListingsYet[lang.langIndex]}
          </h2>
        )}
      </div>
    </div>
  );
};

export default AgentListings;

import axios from 'axios';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Col, Container, Row } from 'react-bootstrap';
import { validateEmail } from '../../constants//helperFunctions';
import { Box, Modal, Typography } from '@mui/material';
import TextTranslation from '../../constants/TextTranslation';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AutocompleteSearch from '../../layouts/HomeSearch/AutocompleteSearch';
import MultiLocationsAutoComplete from '../../miscellaneousComponents/MultiLocationsAutoComplete';
import PhoneNumberField from '../../miscellaneousComponents/PhoneNumberField';
import {
  checkUniqueIdValidity,
  checkUniqueUsernameValidity,
  checkUniqueValidity,
  addAgentToDatabase,
} from '../../../api/dataApi';

const useStyles = makeStyles(() => ({
  main: {
    border: '2px solid #014493',
    padding: 20,
    borderRadius: 20,
    marginTop: '10%',
  },
  profileMain: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  profileImg: {
    margin: 'auto',
    width: 70,
    height: 70,
    borderRadius: 35,
    border: '2px solid #014493',
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileUpload: {
    position: 'absolute',
    marginTop: 30,
    marginLeft: 35,
    color: '#fff',
    backgroundColor: '#014493',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: 1,
  },
  input: {
    height: 33,
    width: '100%',
    border: '2px solid #014493',
    borderRadius: 20,
    color: '#767676',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 11,
    paddingLeft: 10,
    display: 'flex',
    margin: '5px 0',
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    '&::placeholder': {
      color: '#767676',
      fontFamily: 'Poopins-SemiBold',
      fontSize: 11,
    },
    '&:focus': {
      outline: '1px solid #014493',
    },
  },

  btn: {
    width: 141,
    height: 33,
    backgroundColor: '#014493',
    color: '#fff',
    fontFamily: 'Poopins-SemiBold',
    fontSize: 12,
    border: 'none',
    cursor: 'pointer',
    borderRadius: 20,
    marginTop: 10,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    backgroundColor: '#fff',
    border: '1px solid #fff',
    boxShadow: 24,
    padding: 10,
    borderRadius: 20,
  },
}));

const AddAgent = () => {
  const classes = useStyles();
  let history = useHistory();
  // const authentication = sessionStorage.getItem('token');
  // const auth = JSON.parse(authentication);
  const auth = localStorage.getItem('token');

  const [duplicateEmail, setDuplicateEmail] = useState('');
  const [duplicateUsername, setDuplicateUsername] = useState('');
  const [duplicateId, setDuplicateId] = useState('');
  const [img, setImage] = React.useState('');
  const [agent, setAgent] = React.useState({});
  const [areaInfo, setAreaInfo] = React.useState([]);

  const country = useSelector((state) => state.country.country);
  const lang = useSelector((state) => state.language);

  const customInputStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    paddingRight: 10,
  };

  const customErrorStyle = {
    textAlign: lang.langIndex === 2 ? 'right' : 'left',
    marginRight: 5,
    fontSize: 10,
    color: 'red',
    marginLeft: 5,
    fontFamily: 'Poopins-Regular',
  };

  const initialValues = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    cnic: '',
    phone_number: '',
    photo: '',
    city: '',
    area: '',
    address: '',
    is_active: true,
    is_invited: true,
    is_password_changed: false,
    admin: auth?.id,
  };

  const handleImgUpload = (e) => {
    if (e.currentTarget.files[0]) {
      let file = e.currentTarget.files[0];
      setImage(file);
    }
  };

  const chequeUniqueEmail = async (e) => {
    if (validateEmail(e.target.value)) {
      const emailResponse = await checkUniqueValidity(e.target.value);
      if (emailResponse?.status) setDuplicateEmail('');
      else setDuplicateEmail('Email Already Exists');
    }
  };
  const chequeUniqueUsername = async (value) => {
    const usernameResponse = await checkUniqueUsernameValidity(value);
    if (usernameResponse?.status) setDuplicateUsername('');
    else setDuplicateUsername('Username Already Exists');
  };
  const chequeUniqueId = async (e) => {
    const idResponse = await checkUniqueIdValidity(e.target.value);
    if (idResponse?.status) setDuplicateId('');
    else setDuplicateId('CNIC Already Exists');
  };
  const delayedInputSearch = useMemo(
    () => debounce((e) => chequeUniqueEmail(e), 1000),
    []
  );
  const delayedUsernameValidation = useMemo(
    () => debounce((username) => chequeUniqueUsername(username), 1000),
    []
  );
  const delayedIdValidation = useMemo(
    () => debounce((e) => chequeUniqueId(e), 1000),
    []
  );

  let Schema = Yup.object().shape({
    username: Yup.string().required(
      TextTranslation.usernameRequired[lang.langIndex]
    ),
    first_name: Yup.string().required(
      TextTranslation.firstnameRequired[lang.langIndex]
    ),
    last_name: Yup.string().required(
      TextTranslation.lastnameRequired[lang.langIndex]
    ),
    email: Yup.string()
      .email(TextTranslation.invalidEmail[lang.langIndex])
      .required(TextTranslation.emailRequired[lang.langIndex]),
    password: Yup.string().required(
      TextTranslation.passwordRequired[lang.langIndex]
    ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      TextTranslation.passwordMustMatch[lang.langIndex]
    ),
    // phone_number: Yup.string().required(
    //   TextTranslation.phoneNumberNotvalid[lang.langIndex]
    // ),
    cnic: Yup.string().required(TextTranslation.cnicNotValid[lang.langIndex]),
    city: Yup.string().required(TextTranslation.cityRequired[lang.langIndex]),
    //area: Yup.string().required(TextTranslation.areaRequired[lang.langIndex]),
    address: Yup.string().required(
      TextTranslation.addressRequired[lang.langIndex]
    ),
  });

  return (
    <>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={async (values) => {
            let formData = new FormData();
            formData.append('admin', auth?.id);
            formData.append('username', values.username);
            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('email', values.email);
            formData.append('password', values.password);
            formData.append('confirmPassword', values.confirmPassword);
            formData.append('cnic', values.cnic);
            formData.append('phone_number', values.phone_number);
            formData.append('photo', img);
            formData.append('is_active', true);
            formData.append('is_invited', true);
            formData.append('is_password_changed', false);
            formData.append('city', values.city);

            let _temp = areaInfo.map((e) => {
              return e.description;
            });
            formData.append('area', _temp);
            formData.append('address', values.address);

            const agentRes = await addAgentToDatabase(formData);

            if (agentRes.status) {
              setAgent({ ...agent, ...agentRes.result });
              toast.success(JSON.stringify(agentRes.message), {
                position: toast.POSITION.BOTTOM_RIGHT,
                progressStyle: { backgroundColor: '#014493' },
              });
              history.push('/agents');
            } else {
              toast.error(JSON.stringify(agentRes.message), {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }}
        >
          {({ errors, handleChange, touched, handleBlur, isValid }) => (
            <Form>
              <Container>
                <div className={classes.main}>
                  <Row className="justify-content-center">
                    <div className={classes.profileMain}>
                      {img === '' ? (
                        <PersonIcon sx={{ fontSize: 50, color: '#014493' }} />
                      ) : (
                        <img
                          className={classes.profileImg}
                          src={URL.createObjectURL(img)}
                          alt={img ? 'profile-image' : null}
                        />
                      )}

                      <FileUploadOutlinedIcon
                        className={classes.fileUpload}
                        sx={{ fontSize: 18 }}
                      />
                      <input
                        type="file"
                        id="photo"
                        name="photo"
                        accept="image/x-png,image/jpeg"
                        className="custom-file-input absolute-position"
                        onChange={(e) => {
                          handleImgUpload(e);
                        }}
                      />
                    </div>
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{
                      display: 'flex',
                      flexDirection:
                        lang.langIndex === 2 ? 'row-reverse' : 'row',
                    }}
                  >
                    <Col xs={6} sm={6} md={4} lg={4}>
                      <input
                        placeholder={TextTranslation.username[lang.langIndex]}
                        className={classes.input}
                        style={customInputStyle}
                        name="username"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          delayedUsernameValidation(e.target.value);
                          handleChange(e);
                        }}
                      />
                      {errors.username && touched.username ? (
                        <div style={customErrorStyle}>{errors.username}</div>
                      ) : duplicateUsername !== '' ? (
                        <span style={customErrorStyle}>
                          {duplicateUsername}
                        </span>
                      ) : null}
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                      <input
                        placeholder={TextTranslation.firstName[lang.langIndex]}
                        className={classes.input}
                        style={customInputStyle}
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.first_name && touched.first_name ? (
                        <div style={customErrorStyle}>{errors.first_name}</div>
                      ) : null}
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                      <input
                        placeholder={TextTranslation.lastName[lang.langIndex]}
                        className={classes.input}
                        style={customInputStyle}
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.last_name && touched.last_name ? (
                        <div style={customErrorStyle}>{errors.last_name}</div>
                      ) : null}
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                      <input
                        placeholder={TextTranslation.email[lang.langIndex]}
                        className={classes.input}
                        style={customInputStyle}
                        name="email"
                        onBlur={handleBlur}
                        onInput={delayedInputSearch}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.email && touched.email ? (
                        <div style={customErrorStyle}>{errors.email}</div>
                      ) : duplicateEmail !== '' ? (
                        <span style={customErrorStyle}>{duplicateEmail}</span>
                      ) : null}
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                      <InputMask
                        mask="*****-*******-*"
                        maskChar=" "
                        placeholder={`${
                          TextTranslation.cnic[lang.langIndex]
                        } *****-*******-*`}
                        name="cnic"
                        className={classes.input}
                        style={customInputStyle}
                        onInput={delayedIdValidation}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.cnic && touched.cnic ? (
                        <div style={customErrorStyle}>{errors.cnic}</div>
                      ) : duplicateId !== '' ? (
                        <span style={customErrorStyle}>{duplicateId}</span>
                      ) : null}
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                      <PhoneNumberField
                        name="phone_number"
                        country={country}
                        placeholder={TextTranslation.phone[lang.langIndex]}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.phone_number && touched.phone_number ? (
                        <div style={customErrorStyle}>
                          {errors.phone_number}
                        </div>
                      ) : null}
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                      <input
                        name="password"
                        placeholder={TextTranslation.password[lang.langIndex]}
                        type="password"
                        className={classes.input}
                        style={customInputStyle}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.password && touched.password ? (
                        <div style={customErrorStyle}>{errors.password}</div>
                      ) : null}
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                      <input
                        name="confirmPassword"
                        placeholder={
                          TextTranslation.confirmPassword[lang.langIndex]
                        }
                        type="password"
                        className={classes.input}
                        style={customInputStyle}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div style={customErrorStyle}>
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                      <AutocompleteSearch
                        name="city"
                        country={country}
                        searchOption={['(regions)']}
                        customStyle={classes.input}
                        handleChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder={TextTranslation.city[lang.langIndex]}
                      />
                      {errors.city && touched.city ? (
                        <div style={customErrorStyle}>{errors.city}</div>
                      ) : null}
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                      <MultiLocationsAutoComplete
                        name="area"
                        placeholder={TextTranslation.area[lang.langIndex]}
                        value={areaInfo}
                        setValue={setAreaInfo}
                        className={classes.input}
                        style={customInputStyle}
                        handleChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.area && touched.area ? (
                        <div style={customErrorStyle}>{errors.area}</div>
                      ) : null}
                    </Col>

                    <Col xs={6} sm={6} md={8} lg={8}>
                      <AutocompleteSearch
                        name="address"
                        placeholder={TextTranslation.address[lang.langIndex]}
                        country={country}
                        searchOption={['address']}
                        customStyle={classes.input}
                        handleChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <button
                      type="submit"
                      className={classes.btn}
                      disabled={!isValid}
                    >
                      {TextTranslation.saveChanges[lang.langIndex]}
                    </button>
                  </Row>
                </div>
              </Container>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default AddAgent;
